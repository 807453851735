
export default {
  model: {
    prop: 'modelValue',
    event: 'updateValue'
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(v) {
        this.$emit('updateValue', v)
      } 
    }
  },
  methods: {
    onChange(value) {
      this.value = value
    }
  }
}
