
import { mapState } from 'vuex'
import SupportNav from '@/components/page/support-nav.vue'
import SupportTop from '@/components/page/support-top.vue'
import SelfCollection from '@/modules/templates/components/self-collection/index.vue'

export default {
  components: {
    SupportNav,
    SupportTop,
    SelfCollection,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    cmsData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      resizeInfo: (state) => state.resizeInfo,
      site: (state) => state.site,
      isMap: (state) => state.isMap,
    }),
    table() {
      return {
        company: this.$t(
          'serviceSupport.querySupport.service_depts.corporateName'
        ),
        selfSendPointName: this.isSG
          ? this.$t(
              'serviceSupport.querySupport.service_depts.servicePointName'
            )
          : this.$t(
              'serviceSupport.querySupport.service_depts.selfSendPointName'
            ),
        serviceType: this.$t(
          'serviceSupport.querySupport.service_depts.pointType'
        ),
        address: this.$t('serviceSupport.querySupport.service_depts.site'),
        postCode: this.$t('serviceSupport.querySupport.service_depts.postCode'),
        contactInfo: this.$t(
          'serviceSupport.querySupport.service_depts.telephone'
        ),
        direction: this.$t(
          'serviceSupport.querySupport.service_depts.directions'
        ),
        serviceTime: this.$t(
          'serviceSupport.querySupport.service_depts.serviceTime'
        ),
      }
    },
    isSG() {
      return this.site.toUpperCase() === 'SG'
    },
    getMainTitle() {
      return this.isSG ? this.$t('navs.authReseller') : this.title
    },
    getSubTitle() {
      return this.isSG ? this.$t('navs.authReseller') : this.subTitle
    },
  },
  methods: {
    splitString(key, it) {
      const str = it[key]
      const splitList = ['serviceTime', 'selfSendPointName']
      return splitList.includes(key) ? str.replace(/\//g, '\n') : str
    },
  },
}
