
import HeadBanner from '~/components/common/HeadBanner'
export default {
  components: {
    HeadBanner,
  },
  props: {
    result: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    service() {
      return this.result[0] || {}
    },
    content() {
      return this.service.content || ''
    }
  },
}
