
import { mapState } from 'vuex'
import Clipboard from 'clipboard'
import commonMixin from '@/mixins/common'
import NavConfig from '@/modules/config/nav'
import SupportTop from '@/components/page/support-top.vue'

export default {
  components: {
    SupportTop
  },
  mixins: [commonMixin],
  data() {
    return {
      serviceList: [{}]
    }
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isHdFtMobile,
    }),
    lang() {
      return this.$route.params.lang
    },
    site() {
      return this.$route.params.site
    },
    kefu() {
      return NavConfig[this.site]?.kefu[this.lang] || '/'
    }
  },
  mounted() {
    this.initClipboard()
  },
  methods: {
    initClipboard() {
      this.clipboard = new Clipboard('.copy-clipboard')
      this.clipboard.on('success', () => {
        this.showToast({
          message: this.$t('common.form.copySuccess')
        })
      })
    },
    onChatClick() {
      window.open(this.kefu, '_blank');
    },
  }
}
