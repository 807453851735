import { render, staticRenderFns } from "./mb.vue?vue&type=template&id=166a0022&scoped=true&"
import script from "./mb.vue?vue&type=script&lang=js&"
export * from "./mb.vue?vue&type=script&lang=js&"
import style0 from "./mb.vue?vue&type=style&index=0&id=166a0022&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "166a0022",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Imglazy: require('/app/workspace/DEPLOY/ibu-owt-core/ibu-owt-core-owt-ssr-nuxt/components/Imglazy.vue').default})
