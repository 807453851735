
import { addEventListener, removeEventListener } from '@/utils/queryEle'
export default {
  name: 'TabBar',
  props: {
    curSelected: {
      type: Number,
      default: () => 0,
    },
    styleObject: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      curSelectedData: this.curSelected,
      searchBarFixed: false,
    }
  },
  watch: {
    curSelected() {
      this.curSelectedData = this.curSelected
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },
  },
  mounted() {
    addEventListener(document, 'scroll', this.handleScroll)
    this.$nextTick(() => {
      this.curSelectedData = this.curSelected
      this.handleScrollRight()
    })
  },
  destroyed() {
    removeEventListener(document, 'scroll', this.handleScroll)
  },
  methods: {
    onClick(e) {
      this.curSelectedData = Number(e.currentTarget.dataset.pos)
      this.handleScrollRight()
      this.$emit('update:curSelected', Number(e.currentTarget.dataset.pos))
      this.$emit('onItemTabbarClick', { pos: this.curSelectedData })
    },
    handleScrollRight() {
      // 移动端英文环境下附加费需要初始向右滚动
      const parentDom = document.querySelector('.tab-bar-wrapper')
      if (this.curSelectedData === 2) {
        parentDom && (parentDom.scrollLeft = 999)
      } else {
        parentDom && (parentDom.scrollLeft = 0)
      }
    },
    handleScroll() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      const offsetTop = document.querySelector('.tab-bar-wrapper').offsetTop
      if (scrollTop > offsetTop) {
        this.searchBarFixed = true
        const header = document.getElementById('header')
        this.$refs.tab.style = `top: ${header?.offsetHeight}px;`
      } else {
        this.searchBarFixed = false
        this.$refs.tab.style = `top: 0px;`
      }
    },
  },
}
