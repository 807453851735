
import { mapState } from 'vuex'
import data from '../data'
import SupportNav from '@/components/page/support-nav.vue'
import SupportTop from '@/components/page/support-top.vue'
import Tips from '@/modules/templates/default/invoice/tip.vue'
import captcha from '@/utils/captcha.js'
import mixin from '@/mixins/common'
import { waybillsReg } from '@/utils/const'
import Result from './result.vue'
import { InvoiceQueryButton } from '@/reporter/track'

export default {
  components: {
    SupportNav,
    SupportTop,
    Result,
    Tips,
  },
  layout: 'base',
  mixins: [mixin],
  data() {
    return {
      data,
      form: {
        waybillNos: '',
        mobileNoLast4: '',
        postFlag: ''
      },
      loading: false,
      waybillBlur: true,
      radioList: [
        {
          label: this.$t('serviceSupport.invoice.shipper'),
          value: '1'
        },
        {
          label: this.$t('serviceSupport.invoice.consignee'),
          value: '0'
        }
      ],
      rules: {
        waybillNos: [
          {required: true, message: this.$t('common.form.enterLabel', { label: this.$t('serviceSupport.clearanceService.uploadPhotos.waybillNo')}), trigger: 'blur'},
          {pattern: waybillsReg, message: this.$t('serviceSupport.querySupport.waybills.wayBillLengthError'), trigger: 'blur'}
        ],
        mobileNoLast4: [
          {required: true, message: this.$t('common.form.enterLabel', { label: this.$t('common.form.contactLastDigits')}), trigger: 'blur'},
          {pattern: /^[0-9]{4}$/, message: this.$t('common.form.enterRight', { label: this.$t('common.form.contactLastDigits')}), trigger: 'blur'}
        ],
        postFlag: [{required: true, message: this.$t('common.form.selectLabel', { label: this.$t('serviceSupport.invoice.issuer')}), trigger: 'change'}],
      }
    }
  },
  computed: {
    ...mapState({
      lang: (state) => state.lang,
      site: (state) => state.site,
    }),
    isBtnDisabled() {
      return !this.form.waybillNos || !this.form.mobileNoLast4 || !this.form.postFlag
    },
    placeholder() {
      if (this.form.postFlag === '1') {
        return this.$t('serviceSupport.invoice.placeholder1')
      } else if (this.form.postFlag === '2') {
        return this.$t('serviceSupport.invoice.placeholder2')
      } else {
        return this.$t('serviceSupport.invoice.placeholder')
      }
    },
    showWarmTip() {
      return this.isMultiple() && this.waybillBlur
    },
    paymentTypeCode() {
      return this.form.postFlag === '1' ? '1' : '2'
    },
    formParams(){
      return {
        ...this.form,
        waybillNos: this.handleIdsFormat(this.form.waybillNos),
        businessCode: '5',
        paymentTypeCode: this.paymentTypeCode
      }
    }
  },
  created() {
    if (process.client) {
      captcha.init({ lang: this.locale, app: this })
    }
  },
  methods: {
    onFocus() {
      this.waybillBlur = false
    },
    onBlur() {
      const { waybillNos } = this.form
      this.form.waybillNos = this.handleFormat(waybillNos)
      this.waybillBlur = true
    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min)
    },
    handleFormat(str) {
      return str.replace(/(SF(\d{10}|\d{13}[,]?)([,]SF(\d{10}|\d{13})))[,\.\s]+$/g, '$1')
    },
    handleIdsFormat(waybillNos) {
      let str = waybillNos.replace(/[\s,，]+/g, ',')
      this.form.waybillNos = str
      return str.split(',')
    },
    isMultiple() {
      if (waybillsReg.test(this.form.waybillNos)) {
        const waybillNos = this.handleIdsFormat(this.form.waybillNos)
        return waybillNos.length > 1
      }
      return false
    },
    getSlideCaptch() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          captcha.verify().then((res) => {
            this.getData(res)
            InvoiceQueryButton()
          })
        }
      })
    },
    getData(cap) {
      const params = this.formParams
      const headers = {
        memberId: this.randomNum(1000, 10000)
      }
      this.loading = true
      this.$API.support
        .getSlideVnInvoice(params, headers, cap)
        .then((res) => {
          const data = res.result
          if (data) {
            if (data.succ === 'ok') {
              const waybillList = data.result?.waybillList || []
              const tempList = waybillList.filter((it) => it.code === '01')
              if (waybillList.length > 0 && waybillList.length === tempList.length) {
                this.goResult()
              } else {
                this.showWarning({
                  title: this.$t('common.tips.warmReminder'),
                  message: res.detailMessage,
                  onOk: () => {}
                })
              }
            } else {
              this.showWarning({
                title: this.$t('common.tips.warmReminder'),
                message: res.detailMessage || data.msg,
                onOk: () => {}
              })
            }
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    goResult() {
      this.$router.push({
        name: 'site-lang-support-querySupport-invoiceRequest-result',
        params: {
          site: this.site,
          lang: this.lang,
        },
        query: {
          ...this.formParams,
        },
      })
    }
  },
}
