import { render, staticRenderFns } from "./result.vue?vue&type=template&id=1599425b&scoped=true&"
import script from "./result.vue?vue&type=script&lang=js&"
export * from "./result.vue?vue&type=script&lang=js&"
import style0 from "./result.vue?vue&type=style&index=0&id=1599425b&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1599425b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SvgIcon: require('/app/workspace/DEPLOY/ibu-owt-core/ibu-owt-core-owt-ssr-nuxt/components/SvgIcon.vue').default,ImgBase64: require('/app/workspace/DEPLOY/ibu-owt-core/ibu-owt-core-owt-ssr-nuxt/components/img/base64.vue').default})
