
import { mapState } from 'vuex'
import Clipboard from 'clipboard'
import data from './data'
import commonMixin from '@/mixins/common'
import SupportNav from '@/components/page/support-nav.vue'
import SupportTop from '@/components/page/support-top.vue'
import Tips from '@/modules/templates/default/invoice/tip.vue'
import { ImagePreview } from 'vant'

export default {
  components: {
    SupportNav,
    SupportTop,
    Tips,
  },
  mixins: [commonMixin],
  data() {
    return {
      data,
      list: [{
        name: 'navs.auCountry',
        email: 'OC.Finance_AU@kex-sf.com',
        img: require('@/assets/img/invoice/invoice-template-au.png'),
      },{
        name: 'navs.nzCountry',
        email: 'OC.Finance_NZ@kex-sf.com',
        img: require('@/assets/img/invoice/invoice-template-nz.png'),
      }]
    }
  },
  computed: {
    ...mapState({
      locale: (state) => state.locale,
      site: (state) => state.site,
    }),
  },
  mounted() {
    this.initClipboard()
  },
  methods: {
    initClipboard() {
      this.clipboard = new Clipboard('.copy-clipboard')
      this.clipboard.on('success', () => {
        this.showToast({
          message: this.$t('common.form.copySuccess')
        })
      })
    },
    showInvoiceImg(img) {
      ImagePreview([img])
    }
  },
}
