
import Careers from '@/modules/templates/default/careers/index.vue'

export default {
  components: {
    Careers,
  },
  props: {
    result: {
      type: Array,
      default: () => []
    }
  },
}
