
export default {
  name: '',
  props: {
    clientData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  computed: {
    tipConfig() {
      return this.clientData.tips
    },
  },
  methods: {},
}
