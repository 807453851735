/*
 * @Author: yuanxing(01407381)
 * @Date: 2023-02-24 10:31:55
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-12-07 11:35:18
 * @FilePath: \ibu-owt-core-frontend\site\nav\index.js
 * @Description: 右侧导航
 */
import allUrls from '@/utils/config'

const config = {
  cn: {
    navList: [
      {
        id: 3,
        name: 'navs.customerService',
        icon: 'customerService',
      },
      {
        id: 4,
        name: 'navs.businessCooperationBlank',
        icon: 'businessCooperation',
      },
      {
        id: 6, // 功能反馈
        name: 'navs.funcSuggestionBlank',
        icon: 'suggestion',
      },
      {
        id: 5,
        name: 'navs.wechatNo',
        icon: 'wechat',
        src: require('~/assets/img/footer/ic_qrcode_wechat.jpg'),
      },
    ],
    tabList: [
      {
        id: 1,
        name: 'modules.home.broadside.name1',
        type: 'express',
      },
      {
        id: 2,
        name: 'modules.home.broadside.name2',
        type: 'ec',
      },
      {
        id: 3,
        name: 'modules.home.broadside.name3',
        type: 'sfbuy',
      },
    ],
    kefu: '',
    customerUrl: allUrls.iuopCustomerUrl,
  },
  sg: {
    navList: [
      {
        id: 3,
        name: 'navs.customerService',
        icon: 'customerService',
      },
      {
        id: 4,
        name: 'navs.businessCooperationBlank',
        icon: 'businessCooperation',
      },
      {
        id: 6, // 功能反馈
        name: 'navs.funcSuggestionBlank',
        icon: 'suggestion',
      },
    ],
    tabList: [],
    kefu: {
      sc: 'https://kefu.sf.global/webim/im.html?configId=698a10f7-c337-4bfd-817c-61c5937e63d1',
      en: 'http://kefu.sf.global/webim/im.html?configId=3a2abf51-ea8c-49eb-baa6-31c94157611f',
    },
    customerUrl: allUrls.iuopGlobalCustomerUrl,
  },
  my: {
    navList: [
      {
        id: 3,
        name: 'navs.customerService',
        icon: 'customerService',
      },
      {
        id: 4,
        name: 'navs.businessCooperationBlank',
        icon: 'businessCooperation',
      },
      {
        id: 6, // 功能反馈
        name: 'navs.funcSuggestionBlank',
        icon: 'suggestion',
      },
    ],
    tabList: [],
    kefu: {
      sc: 'https://kefu.sf.global/webim/im.html?configId=1fd82e7f-c0a2-4bb7-905d-3ec7a7016f90',
      en: 'https://kefu.sf.global/webim/im.html?configId=01bcfb07-f2a0-4d5b-8b8f-9b2c61b1fdcd',
    },
    customerUrl: allUrls.iuopGlobalCustomerUrl,
  },
  th: {
    navList: [
      {
        id: 3,
        name: 'navs.customerService',
        icon: 'customerService',
      },
      {
        id: 4,
        name: 'navs.businessCooperationBlank',
        icon: 'businessCooperation',
      },
      {
        id: 6, // 功能反馈
        name: 'navs.funcSuggestionBlank',
        icon: 'suggestion',
      },
    ],
    tabList: [],
    kefu: {
      sc: 'http://kefu.sf.global/webim/im.html?configId=55b6e172-f436-4e42-a430-eacc02e40cf5',
      en: 'http://kefu.sf.global/webim/im.html?configId=163f6d28-2aa7-4277-9990-79890c41a5b3',
      th: 'http://kefu.sf.global/webim/im.html?configId=2068e30e-4cf9-41a1-974e-e1112eae40bc',
    },
    customerUrl: allUrls.iuopGlobalCustomerUrl,
  },
  id: {
    navList: [
      {
        id: 3,
        name: 'navs.customerService',
        icon: 'customerService',
      },
      {
        id: 4,
        name: 'navs.businessCooperationBlank',
        icon: 'businessCooperation',
      },
      {
        id: 6, // 功能反馈
        name: 'navs.funcSuggestionBlank',
        icon: 'suggestion',
      },
    ],
    tabList: [
      {
        id: 4,
        name: 'modules.home.broadside.name4', // 国际件
        type: 'international',
      },
      {
        id: 5,
        name: 'modules.home.broadside.name5', // 本地件
        type: 'domestic',
      },
    ],
    // kefu: {
    //   sc: '/id/sc/support/service',
    //   en: '/id/en/support/service',
    // },
    customerUrl: allUrls.iuopGlobalCustomerUrl,
  },
  ph: {
    navList: [
      // {
      //   id: 3,
      //   name: 'navs.customerService',
      //   icon: 'customerService',
      // },
      {
        id: 4,
        name: 'navs.businessCooperationBlank',
        icon: 'businessCooperation',
      },
      {
        id: 6, // 功能反馈
        name: 'navs.funcSuggestionBlank',
        icon: 'suggestion',
      },
    ],
    tabList: [],
    // kefu: {
    //   sc: '/id/sc/support/service',
    //   en: '/id/en/support/service',
    // },
    customerUrl: allUrls.iuopGlobalCustomerUrl,
  },
  vn: {
    navList: [
      {
        id: 3,
        name: 'navs.customerService',
        icon: 'customerService',
      },
      {
        id: 4,
        name: 'navs.businessCooperationBlank',
        icon: 'businessCooperation',
      },
      {
        id: 6, // 功能反馈
        name: 'navs.funcSuggestionBlank',
        icon: 'suggestion',
      },
    ],
    tabList: [],
    kefu: {
      sc: 'https://kefu.sf.global/webim/im.html?configId=12f239be-2dc4-4262-9bfb-1dc45484dbf1',
      en: 'http://kefu.sf.global/webim/im.html?configId=3d3ad761-44d6-496c-b43e-a603a177142c',
      vi: 'https://kefu.sf.global/webim/im.html?configId=c635c4ee-10dc-4141-a962-1879016d6ca4',
    },
    customerUrl: allUrls.iuopGlobalCustomerUrl,
  },
  jp: {
    navList: [
      {
        id: 4,
        name: 'navs.businessCooperationBlank',
        icon: 'businessCooperation',
      },
      {
        id: 6, // 功能反馈
        name: 'navs.funcSuggestionBlank',
        icon: 'suggestion',
      },
      {
        id: 3,
        name: 'navs.customerService',
        icon: 'customerService',
      },
    ],
    tabList: [],
    kefu: {
      sc: 'https://kefu.sf.global/webim/im.html?configId=da680710-1acc-403e-b61b-094c89555a99',
      en: 'https://kefu.sf.global/webim/im.html?configId=3aa10c40-3bec-44ab-94f7-23ba7109051e',
      ja: 'https://kefu.sf.global/webim/im.html?configId=d1effe21-6bcb-4af8-b6f6-9f28f023fdfd',
    },
    customerUrl: allUrls.iuopGlobalCustomerUrl,
  },
  au: {
    navList: [
      {
        id: 4,
        name: 'navs.businessCooperationBlank',
        icon: 'businessCooperation',
      },
      // {
      //   id: 6, // 功能反馈
      //   name: 'navs.funcSuggestionBlank',
      //   icon: 'suggestion',
      // },
      {
        id: 3,
        name: 'navs.customerService',
        icon: 'customerService',
      },
    ],
    tabList: [],
    kefu: {
      sc: 'http://kefu.sf.global/webim/im.html?configId=1fd82e7f-c0a2-4bb7-905d-3ec7a7016f90',
      en: 'https://kefu.sf.global/webim/im.html?configId=01bcfb07-f2a0-4d5b-8b8f-9b2c61b1fdcd',
    },
    customerUrl: allUrls.iuopGlobalCustomerUrl,
  },
}

export default config
