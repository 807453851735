/*
 * @Description:
 * @Author: AaronDing
 * @Date: 2021-11-08 19:49:31
 * @LastEditTime: 2021-11-08 20:09:34
 * @FilePath: \ibu-owt-core-frontend\utils\date.js
 * @LastEditors: AaronDing
 */
const moment = require('moment');

export const formatTime = (time, format) => {
  if (!time) {
    return ''
  }
  const types = Object.prototype.toString.call(time)
  if (types === '[object Number]') {
    const currentDate = new Date(time)
    if (format) {
      //   format = format || 'yyyy/MM/dd'
      const date = {
        'M+': currentDate.getMonth() + 1,
        'd+': currentDate.getDate(),
        'h+': currentDate.getHours(),
        'm+': currentDate.getMinutes(),
        's+': currentDate.getSeconds(),
      }
      if (/(y+)/i.test(format)) {
        format = format.replace(
          RegExp.$1,
          (currentDate.getFullYear() + '').substr(4 - RegExp.$1.length)
        )
      }
      for (const k in date) {
        if (isNaN(date[k])) {
          return time
        }
        if (new RegExp('(' + k + ')').test(format)) {
          format = format.replace(
            RegExp.$1,
            RegExp.$1.length === 1
              ? date[k]
              : ('00' + date[k]).substr(('' + date[k]).length)
          )
        }
      }
      return format
    }
    const year = currentDate.getFullYear()
    const month = currentDate.getMonth() + 1
    const day = currentDate.getDate()
    const hour = currentDate.getHours()
    const minute = currentDate.getMinutes()
    const second = currentDate.getSeconds()
    return (
      [year, month, day].map(formatNumber).join('-') +
      ' ' +
      [hour, minute, second].map(formatNumber).join(':')
    )
  } else if (types === '[object String]') {
    const times = ISO8601DateStr2Date(time)
    return renderDateTime(times.toString())
  }
}

export const renderDateTime = function (jsondate) {
  let timeStamp = parseInt(jsondate.replace(/\D/gim, ''))
  timeStamp = timeStamp - 8 * 60 * 60 * 1000
  const date = new Date(timeStamp)

  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hour = date.getHours()
  const minute = date.getMinutes()
  const second = date.getSeconds()
  return (
    year +
    '-' +
    (month < 10 ? '0' + month : month) +
    '-' +
    (day < 10 ? '0' + day : day) +
    ' ' +
    (hour < 10 ? '0' + hour : hour) +
    ':' +
    (minute < 10 ? '0' + minute : minute) +
    ':' +
    (second < 10 ? '0' + second : second)
  )
}

export const ISO8601DateStr2Date = (string) => {
  const regexp =
    '([0-9]{4})(-([0-9]{2})(-([0-9]{2})' +
    '(T([0-9]{2}):([0-9]{2})(:([0-9]{2})(.([0-9]+))?)?' +
    '(Z|(([-+])([0-9]{2}):([0-9]{2})))?)?)?)?'
  if (string) {
    const d = string.match(new RegExp(regexp))
    let offset = 0
    const date = new Date(d[1], 0, 1)

    if (d[3]) {
      date.setMonth(d[3] - 1)
    }
    if (d[5]) {
      date.setDate(d[5])
    }
    if (d[7]) {
      date.setHours(d[7])
    }
    if (d[8]) {
      date.setMinutes(d[8])
    }
    if (d[10]) {
      date.setSeconds(d[10])
    }
    if (d[12]) {
      date.setMilliseconds(Number('0.' + d[12]) * 1000)
    }
    if (d[14]) {
      offset = Number(d[16]) * 60 + Number(d[17])
      offset *= d[15] === '-' ? 1 : -1
    }
    offset -= date.getTimezoneOffset()
    const time = Number(date) + offset * 60 * 1000
    return time
  } else {
    return null
  }
}

/**
 * 优先使用这个格式化UTC时间 UTC -> yyyy-MM-DD HH:mm:ss
 * 使用dayjs 不支持yyyy，
 * lzb
 * @param utcTime
 * @returns {string}
 */
export const formatTimeWithMoment = (utcTime, format) => {
  return moment(utcTime).format(format || 'yyyy-MM-DD HH:mm:ss')
}

/**
 * 时间戳转UTC
 * lzb
 * @param utcTime
 * @returns {string}
 */
export const getUTCTimeWithTimestamp = (timeStamp) => {
  return moment(moment(timeStamp).format('yyyy-MM-DD HH:mm:ss')).utc(8).format() // 东8区
}

export const formatNumber = (n) => {
  n = n.toString()
  return n[1] ? n : '0' + n
}

export default {
  formatTime,
  formatTimeWithMoment,
  getUTCTimeWithTimestamp,
}
