
import data from './data'
import Product from '@/modules/templates/default/product/index.vue'

export default {
  components: {
    Product,
  },
  data() {
    return {
      data,
    }
  },
}
