import Consign from '../sg/consign/index.vue'
import Product from '../sg/product/index.vue'
import Contact from '../sg/contact/index.vue'
import Dept from '../th/dept/index.vue'
import Invoice from './invoice/index.vue'
import Index from './index/index.vue'

export const pages = {
  index: Index, // 首页
  consign: Consign, // 服务支持 寄件流程指引
  product: Product, // 物流服务 物流产品详情
  contact: Contact, // 联系我们
  dept: Dept, // 服务网点
  invoice: Invoice, // 发票申请
}
