
import { getPathInfo, changeSFExpressUrlByLang } from '@/utils/utils'
import { RouterSupportRequire } from '@/router'
import { TRACK_ENTRY_MAP } from '@/utils/const/apply'
export default {
  props: {
    isFinite: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ...RouterSupportRequire(this.$route.params.site),
      detailName: '',
      nowIndex: -1,
    }
  },
  computed: {
    title() {
      return this.isFinite
        ? this.$t('navs.querySupport')
        : this.$t('navs.clearanceService')
    },
    pcList() {
      const lang = this.$route.params.lang
      // 电脑端菜单列表，有些可能与移动端不一致
      const result = this.isFinite
        ? this.QuerySupportList
        : this.customsClearanceList
      const result1 = result?.filter(
        (item) =>
          !item.isMoblieOnly && !item?.hidden?.hiddenLangList?.includes(lang)
      )
      return this.adjustLinkParams(result1) || []
    },
    mobileList() {
      // 移动端菜单列表
      const result = this.isFinite
        ? this.QuerySupportList
        : this.customsClearanceList
      return this.adjustLinkParams(result) || []
    },
  },
  mounted() {
    this.setDefaultActive()
    this.$nextTick(() => {
      this.setScrollBarLeft()
    })
  },
  methods: {
    switchItem(switchKey) {
      if (process.client) {
        return window?.[switchKey] || false
      }
      return false
    },
    setDefaultActive() {
      // 选中
      const { pathStr } = getPathInfo(this.$route)
      this.detailName = pathStr
    },
    // 当tab位置处于屏幕右侧看不见的地方时，设置scrollLeft
    setScrollBarLeft() {
      const parentDom = document.querySelector('.tab-ul-mobile')
      const activeDomInfo = document
        .querySelector('.tab-ul-mobile .active')
        ?.getBoundingClientRect()
      const clientWidth = document.body.clientWidth
      if (activeDomInfo?.left > (clientWidth * 2) / 3) {
        parentDom.scrollLeft = activeDomInfo?.left
      }
    },
    // 跳转
    changePath(pathKey, index, citem) {
      this.nowIndex = index
      citem?.trackFn && citem?.trackFn()
      if (pathKey?.match(/^http(s)?:/)) {
        const routeParams = this.$route.params
        // 当配置中存在可替换的语言时，需要根据当前语言环境跳转到对应外链的语言环境
        // if (citem.lang && citem.lang.includes(routeParams.lang)) {
        // location.href = pathKey.replace(/\/sc\//, `/${routeParams.lang}/`)
        // } else {
        // location.href = pathKey
        // }
        if (pathKey.includes(window.g.sfExpressUrl)) {
          const href = changeSFExpressUrlByLang({
            url: pathKey,
            lang: routeParams.lang,
          })
          location.href = href
        } else if (citem.lang && citem?.lang?.includes(routeParams.lang)) {
          location.href = pathKey.replace(/\/sc\//, `/${routeParams.lang}/`)
        } else {
          location.href = pathKey
        }
      } else {
        const { path } = this.$to(pathKey)
        this.$router.push({
          path,
        })
      }
    },
    handleHrefClick(item) {
      item?.trackFn && item?.trackFn()
    },
    adjustLinkParams(rawData) {
      return rawData?.map((it) => {
        const copy = { ...it }
        // 在线申报需要调整场景
        if (copy.path === '/support/customsClearance/apply') {
          copy.params = {
            query: {
              scene: TRACK_ENTRY_MAP.clearanceService,
            },
          }
        }
        return copy
      })
    },
  },
}
