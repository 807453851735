/**
 * 意见反馈、满意度
 */
// 渠道编码映射表
export const channelCodeMap = {
  sfintlOsPc: 'sfintlOsPc', // 国际官网PC端
  sfintlOsH5: 'sfintlOsH5', // 国际官网移动端
  IBU_FENGHE_HTMCWS: 'hkApp', // 香港App
  'ICCSP-MINIAPP': 'sfintlWxmini', // 国际小程序
  'ICCSP-APP': 'sfintlApp', // 国际App
  'EOS-ICSM-CORE': 'sfMp', // 速运公众号
  'ICCSP-SFGO': 'sfgoH5', // sfgo
}

// 场景码-意见反馈
export const suggestionSceneCodeMap = {
  rightNav: 'F100001', // PC端全局侧边栏，移动端仅首页
  topNav: 'F100002', // 支持 > 功能反馈
}
