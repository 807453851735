import allUrls from '@/utils/config'

const data = {
  sendLink: {
    href: allUrls.iuopGloballoginUrl,
    name: 'serviceSupport.consigns.shippingBtn',
  },
}

export default data
