
export default {
  props: {
    list: {
      default: () => [],
      type: Array,
    },
    // https://github.com/vueComponent/ant-design-vue/blob/master/components/vc-slick/src/default-props.js#L3
    // 参数设置
    carouselProps: {
      default: () => {
        return {
          speed: 500,
          autoplaySpeed: 3000,
          autoplay: true,
          arrows: true,
        }
      },
      type: Object,
    },
  },
  data() {
    return {
      tempX: 0,
      tempY: 0,
    }
  },
  mounted() {},
  methods: {
    // 移动端的click事件被屏蔽了，且冒泡被屏蔽了
    handleTouchStart(e) {
      this.tempX = 0
      this.tempY = 0
      const touch = e.changedTouches[0]
      this.tempX = touch.pageX
      this.tempY = touch.pageY
    },
    handleTouchEnd(e, item) {
      const touch = e.changedTouches[0]
      // 表示用户进行了点击，不是滑动
      if (
        this.tempX !== 0 &&
        this.tempX === touch.pageX &&
        this.tempY === touch.pageY
      ) {
        this.tempX = 0
        this.tempY = 0
        window.location.href = item.url
      }
    },
  },
}
