// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../assets/img/service-support/tech-top.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media screen and (min-width:1101px){.top-banner[data-v-005f4aeb]{height:1.06rem;line-height:1.06rem;color:#fff;font-size:0.24rem;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%;background-position:50%;background-color:#333}.top-banner .banner-inner[data-v-005f4aeb]{color:#fff;width:12rem;margin:0 auto;padding-left:0.34rem}}@media screen and (max-width:1100px){.top-banner[data-v-005f4aeb]{height:1.06rem;line-height:1.06rem;color:#fff;font-size:0.36rem;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:cover;background-position:61%;background-color:#333}.top-banner .banner-inner[data-v-005f4aeb]{color:#fff;width:100%;margin:0 auto;padding-left:0.34rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
