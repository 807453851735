
import { mapState } from 'vuex'
import { addEventListener, removeEventListener } from '@/utils/queryEle'
import { debounce } from '@/utils/utils'
export default {
  name: '',

  data() {
    return {
      visible: false,
    }
  },
  computed: {
    ...mapState({
      resizeInfo: (state) => state.resizeInfo,
    }),
  },
  mounted() {
    addEventListener(document, 'scroll', debounce(this.handleScroll))
  },
  destroyed() {
    removeEventListener(document, 'scroll', debounce(this.handleScroll))
  },
  methods: {
    handleBack2Top() {
      document.body.scrollTop = document.documentElement.scrollTop = 0
    },
    handleScroll() {
      // 超过一屏才展示
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      const clientHeight = document.body.clientHeight
      if (scrollTop > clientHeight) {
        this.visible = true
      } else {
        this.visible = false
      }
    },
  },
}
