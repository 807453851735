
import Careers from '@/modules/templates/jp/careers/index.vue'

export default {
  components: {
    Careers
  },
  props: {
    result: {
      type: Array,
      default: () => []
    }
  },
  computed: {},
}
