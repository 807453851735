/*
 * @Descripttion: 公共方法
 * @version: V1.0
 * @Author: yuanxing(01407381)
 * @Date: 2022-08-22 14:52:16
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-01-11 15:35:31
 */
import { mapState } from 'vuex'
const mixin = {
  data() {
    return {}
  },
  computed: {
    ...mapState({
      resizeInfo: (state) => state.resizeInfo,
    }),
  },
  created() {},
  methods: {
    showMessage({ message, type, icon }) {
      icon = type === 'success' ? require('~/assets/img/success.png') : icon
      if (this.resizeInfo.isMobile) {
        this.$toast({
          type: type || 'text',
          icon,
          message,
          duration: 3000,
        })
      } else {
        this.$warning({
          content: message,
          okText: this.$t('button.confirm'),
        })
      }
    },
    // 常规异常提示
    showToast({ message }) {
      if (this.resizeInfo.isMobile) {
        this.$toast({
          message,
        })
      } else {
        this.$message.warning(message)
      }
    },
    showSuccess({ title, message, okText, onOk, icon }) {
      if (this.resizeInfo.isMobile) {
        this.$dialog
          .alert({
            title,
            icon,
            confirmButtonText: okText,
            message,
            theme: 'round-button',
          })
          .then(() => {
            onOk.call(this)
          })
      } else {
        this.$success({
          content: message,
          okText,
          onOk: onOk.bind(this),
        })
      }
    },
    showWarning({ title, message, okText, onOk, icon }) {
      if (this.resizeInfo.isMobile) {
        this.$dialog
          .alert({
            title,
            icon,
            confirmButtonText: okText,
            message,
            theme: 'round-button',
          })
          .then(() => {
            onOk.call(this)
          })
      } else {
        this.$warning({
          content: (h) =>
            h('div', {
              domProps: {
                innerHTML: message,
              },
            }),
          okText,
          onOk: onOk.bind(this),
        })
      }
    },
    showConfirm({
      title,
      message,
      okText,
      onOk,
      cancelText,
      onCancel,
      titleIcon,
    }) {
      if (this.resizeInfo.isMobile) {
        this.$dialog
          .confirm({
            title,
            confirmButtonText: okText,
            cancelButtonText: cancelText,
            message,
            theme: 'round-button',
          })
          .then(() => {
            onOk?.call(this)
          })
          .catch(() => {
            onCancel?.call(this)
          })
      } else {
        // domProps兼容内嵌html结构
        this.$confirm({
          title,
          closable: true,
          content: (h) => {
            return h('div', { domProps: { innerHTML: message } })
          },
          okText,
          okType: 'danger',
          centered: 'centered',
          icon: (h) => {
            if (titleIcon) {
              return h('img', {
                domProps: titleIcon,
              })
            } else {
              return null
            }
          },
          cancelText,
          onOk: onOk?.bind(this),
          onCancel: onCancel?.bind(this),
        })
      }
    },
  },
}
export default mixin
