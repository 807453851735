
import { mapState } from 'vuex'
import { servicePointList } from './cofig'
import CtSelect from '@/components/select/search.vue'
import CtSelectHttp from '@/components/select/searchHttp.vue'
import CtMap from '@/components/map/index.vue'

export default {
  components: {
    CtMap,
    CtSelect,
    CtSelectHttp,
  },
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {
        netType: 0,
        keyword: [],
      },
      postCode: undefined,
      postTempCode: undefined,
      layout: 'vertical',
      data: {},
      radioValue: 1,
      filterList: [],
      postList: [],
      postPoint: {},
      notFoundContent: '',
      countryInfo: {
        sg: {
          zoom: 12,
          center: [103.8392716, 1.3090769],
          extent: [103.499196, 1.109763, 104.159749, 1.572434],
        },
        my: {
          zoom: 6,
          center: [108.6793047, 4.1392257],
          extent: [94.398746, 0.1043689, 118.8705297, 14.637326],
        },
        th: {
          zoom: 5,
          center: [100.56039, 13.79168],
          extent: [92.86835, 5.06731, 108.86144, 22.46386],
        },
        id: {
          zoom: 3,
          center: [108.6793047, -2.1392257],
          extent: [92.398746, -15.1043689, 145.8705297, 9.637326],
        },
        ph: {
          zoom: 3,
          center: [115.6793047, 12.1392257],
          extent: [100.398746, 2.1392257, 146.8705297, 23.1392257],
        },
        vn: {
          zoom: 2,
          center: [109.56039, 15.79168],
          extent: [79.86835, 5.06731, 132.86144, 30.46386],
        },
        au: {
          zoom: 1,
          center: [132.56039, -28.79168],
          extent: [88.86835, -56.41, 180.86144, 20.36],
        },
      },
    }
  },
  computed: {
    ...mapState({
      site: (state) => state.site,
      locale: (state) => state.locale,
    }),
    isTH() {
      return this.site?.toUpperCase() === 'TH'
    },
    isSg() {
      return this.site?.toUpperCase() === 'SG'
    },
    isTHLang() {
      return this.$route.params.lang.toUpperCase() === 'TH'
    },
    isScOrEn() {
      return ['SC', 'EN'].includes(this.$route.params.lang.toUpperCase())
    },
    getAddress() {
      return (data) => {
        if (this.isScOrEn) {
          return data.addressEn || data.address
        } else {
          return data.address || data.addressEn
        }
      }
    },
    isSc() {
      return this.locale.includes('zh')
    },
    hasValue() {
      return Object.keys(this.data).length > 0
    },
    isNet() {
      return this.radioValue === 1
    },
    selectList() {
      const dataTypeList = this.dataList.map((data) => data.serviceType)
      const pointList = servicePointList(this) || []
      return pointList.filter(
        (it) => dataTypeList.includes(it.value) || it.value === 0
      )
    },
    attrs() {
      return this.countryInfo[this.site]
    },
    options() {
      return this.dataList
        .filter((it) =>
          this.form.netType ? it.serviceType === this.form.netType : true
        )
        .map((it) => {
          return {
            value: `${it.postCode}_${it.contactInfo}`,
            label: `${it.selfSendPointName} / ${
              it.postCode
            } / ${this.getAddress(it)}`,
          }
        })
    },
  },
  watch: {
    postTempCode: {
      handler() {
        this.setNotFoundContent()
      },
    },
  },
  mounted() {
    this.filterList = this.dataList
  },
  methods: {
    radioChange() {
      this.reset()
    },
    setNotFoundContent() {
      this.notFoundContent =
        this.postTempCode && this.postTempCode.length < 3 ? (
          <div class="no-point-content">
            {this.$t('serviceSupport.querySupport.service_depts.postLength', {
              len: 3,
            })}
          </div>
        ) : (
          ''
        )
    },
    postSearch(ev) {
      this.postTempCode = ev
      if (ev && ev.length > 2) {
        const params = {
          countryCode: this.site?.toUpperCase(),
          postCode: ev,
        }
        this.$API.support.getPostBound(params).then((res) => {
          this.postList = (res || []).map((it) => {
            it.label = it.countryName + ' ' + it.postCode
            it.value = it.postCode
            return it
          })
        })
      } else {
        this.postList = []
      }
    },
    postChange(item) {
      if (item) {
        const current = this.postList.find((it) => it.postCode === item) || {}
        current.longitude = current.longitude - 0
        current.latitude = current.latitude - 0
        this.postPoint = current
        this.postTempCode = undefined
      } else {
        this.postPoint = {}
      }
    },
    selectChange() {
      this.form.keyword = []
    },
    selected(data) {
      this.data = data
    },
    reset() {
      this.postCode = undefined
      this.form = {
        netType: 0,
        keyword: [],
      }
      this.searchNet()
      this.$refs.map.resetPoint()
    },
    filter(netType, keyword, it) {
      return (
        (netType ? it.serviceType === netType : true) &&
        (keyword.length
          ? keyword.includes(`${it.postCode}_${it.contactInfo}`)
          : true)
      )
    },
    searchNet() {
      const { netType, keyword } = this.form
      if (!netType && !keyword.length) {
        this.filterList = this.dataList
      } else {
        this.filterList = this.dataList.filter((it) => {
          return this.filter(netType, keyword, it)
        })
      }
    },
    searchPost() {
      this.$refs.map.setPoint(this.postPoint)
    },
    search() {
      const timer = setTimeout(() => {
        clearTimeout(timer)
        if (this.isNet) {
          this.searchNet()
        } else if (this.postCode && this.postCode.length > 2) {
          if (Object.keys(this.postPoint).length) {
            this.searchPost()
            this.searchNet()
          }
        }
      }, 0)
    },
  },
}
