import { render, staticRenderFns } from "./-TabContainer3.vue?vue&type=template&id=f3a6ca8c&scoped=true&"
import script from "./-TabContainer3.vue?vue&type=script&lang=js&"
export * from "./-TabContainer3.vue?vue&type=script&lang=js&"
import style0 from "./-TabContainer3.vue?vue&type=style&index=0&id=f3a6ca8c&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f3a6ca8c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OssImg: require('/app/workspace/DEPLOY/ibu-owt-core/ibu-owt-core-owt-ssr-nuxt/components/OssImg.vue').default,LinkTo: require('/app/workspace/DEPLOY/ibu-owt-core/ibu-owt-core-owt-ssr-nuxt/components/LinkTo.vue').default})
