
import { mapState } from 'vuex'
import {
  findTargetParents,
  addEventListener,
  removeEventListener,
} from '@/utils/queryEle'
import { isEmptyObject, debounce } from '@/utils/utils'
import {
  LEVELBASE,
  TABTYPE,
  SUBTYPEBASE,
  inlandCode,
  hmtAndoverseas,
  CountryCode,
  AreaRateCode,
} from '@/utils/constBase'

// 区域选择 原寄地和目的地
const areaTypeBase = {
  origin: 'origin',
  dest: 'dest',
}

// 初始选择的区域
// const initArea = {
//   // // 国家
//   countryRateCode: '',
//   countryCode: 'A000086000',
//   // 省份
//   provinceCode: 'A130000000',
//   province: '河北省',
//   // 城市
//   cityCode: 'A131000000',
//   city: '廊坊市',
//   countyCode: 'A131003000',
//   // 地区
//   county: '广阳区',
//   locationCode: '316',
//   code: 'A131003000',
//   regionType: 'inland',

//   // 常用市
//   city: '深圳市',
//   cityCode: 'A440300000',
//   countryCode: 'A000086000',
//   county: '龙华区',
//   countyCode: 'A4403001768',
//   locationCode: '755',
//   regionType: 'inland',
// }

const SUBSELECTBase = [
  {
    cname: '请选择',
    il8nName: 'common.countryArea.select',
    type: SUBTYPEBASE.select,
    isSub: true,
  },
]

export default {
  props: {
    placeholder: {
      default: () => '',
      type: String,
    },
    // 选择区域类型 原寄地 origin， 目的地 dest
    // 默认为 原寄地
    type: {
      default: () => areaTypeBase.origin,
      type: String,
    },
    // 目的地需要传入 原寄地参数
    originCode: {
      default: () => '',
      type: String,
    },
    // form 表单参数名称
    name: {
      default: () => '',
      type: String,
    },
    // 展示分割符号
    splitSym: {
      default: () => '/',
      type: String,
    },
    // 传入 输入框 属性设置
    propsData: {
      default: () => {
        return {}
      },
      type: Object,
    },
    defaultValue: {
      default: () => {
        return {}
      },
      type: Object,
    },
    // 传入form 变量 必须
    form: {
      default: () => {
        return {}
      },
      type: Object,
    },
    // 可以自由选择不限制
    areaSingle: {
      default: () => {
        return false
      },
      type: Boolean,
    },
    // 是否展示 内地 港澳台 国际
    // 默认都展示
    showTabRule: {
      default: () => {
        return {
          [TABTYPE.inland]: true,
          [TABTYPE.hmt]: true,
          [TABTYPE.overseas]: true,
        }
      },
      type: Object,
    },
    // 是否支持筛选-默认不筛选
    filter: {
      default: () => {
        return false
      },
      type: Boolean,
    },
    defaultIndex: {
      default: 0,
      type: Number,
    }, // 默认展示的一级菜单
  },
  data() {
    return {
      // 具体展示区 页码
      detailCurrent: 1,
      // 具体展示区 页数
      detailPageSize: 20,
      // tab
      selectTabList: [
        {
          cname: '中国内地',
          il8nName: 'common.countryArea.china',
          type: TABTYPE.inland,
          code: inlandCode,
          defaultSub: SUBTYPEBASE.popCity,
        },
        {
          cname: '港澳台',
          il8nName: 'common.countryArea.hmt',
          type: TABTYPE.hmt,
          code: hmtAndoverseas,
          defaultSub: SUBTYPEBASE.hmt,
        },
        {
          cname: '国际',
          il8nName: 'common.countryArea.gl',
          type: TABTYPE.overseas,
          code: hmtAndoverseas,
          defaultSub: SUBTYPEBASE.overseas,
        },
      ],
      // 选择 tab 类型
      selectTabType: '',
      // 选择 tab 第二层
      selectSubTabType: '',
      // 选择  第三层
      selectDetailTabType: '',
      // 第二层 tab基础数据
      subTabBase: {
        [TABTYPE.inland]: [
          {
            cname: '常用市',
            il8nName: 'common.countryArea.pcity',
            type: SUBTYPEBASE.popCity,
            isSub: true,
          },
          {
            cname: '省/直辖市',
            il8nName: 'common.countryArea.pro',
            type: SUBTYPEBASE.province,
            isSub: true,
          },
        ],
        [TABTYPE.hmt]: [
          {
            cname: '港澳台',
            il8nName: 'common.countryArea.hmt',
            type: SUBTYPEBASE.hmt,
            isSub: true,
          },
        ],
        [TABTYPE.overseas]: [
          {
            cname: '海外',
            il8nName: 'common.countryArea.ovsAll',
            type: SUBTYPEBASE.overseas,
            isSub: true,
          },
        ],
      },
      // 第二层 展示tab
      subShowTabList: [],
      // 具体 全数据
      detailShowList: [],
      // 具体 分页展示的数据
      // detailPageShowList: [],
      // 热门城市
      popularCities: [],
      // select 选择的参数
      selectArea: {},
      // select 选择的展示的
      selectAreaDefault: {},
      // 是否展示选择区域框
      isAreaShow: false,
      // 搜索问题
      filterText: '',
      // 是否展示搜索栏
      isFilterShow: false,
      // 当前页码
      currentPage: 1,
      // 默认的每页条数
      defaultPageSize: 8,
      // filterList 搜索数值 模拟数据
      filterList: [],
    }
  },
  computed: {
    ...mapState({
      // popularCities: (state) => state.support.popularCities,
    }),
    selectValue: {
      get() {
        const { countryName, province, city, county } = this.selectAreaDefault
        const type = this.selectTabType
        // 是否可以设置值，海外国家需选择到城市 国内需要选择到县区
        const isPass = this.isReSetVal(this.selectAreaDefault, type)
        if (!this.isFilterShow) {
          const str = [countryName, province, city, county]
            .filter((item) => item)
            .join(this.splitSym)
          if (isPass) {
            return str
          }
        }
        return this.filterText
      },
      set(val) {},
    },
  },
  watch: {
    selectValue(val) {
      // 直接全部返回 让页面自行处理
      this.form.setFieldsValue({
        [this.name]: val
          ? {
              ...this.selectAreaDefault,
            }
          : null,
      })
      setTimeout(() => {
        this.$emit('handleChange')
      })
    },
    // 两个弹窗不能同时打开
    isFilterShow(val) {
      if (val) {
        this.close()
      } else {
        // 页码初始化
        this.currentPage = 1
      }
    },
    isAreaShow(val) {
      if (val) {
        this.isFilterShow = false
        if (isEmptyObject(this.selectAreaDefault)) {
          this.init()
        }
      }
    },
    // 选择常用市
    selectSubTabType(val, preVal) {
      // 获取常用城市
      if (val === SUBTYPEBASE.popCity) {
        this.getCity()
      } else if (val === SUBTYPEBASE.province) {
        // 去获取省份数据
        this.getRateSub({
          parentDistCode: inlandCode,
          level: 1,
        })
      } else if (val === SUBTYPEBASE.hmt) {
        // 去获取港澳台信息
        this.getRateHMTSub({
          parentDistCode: hmtAndoverseas,
          level: 2,
        })
      } else if (val === SUBTYPEBASE.overseas) {
        // 重新获取海外信息
        this.adaptAreaGlobalSub({
          parentDistCode: hmtAndoverseas,
          level: 1,
        })
      }
    },
  },
  mounted() {
    addEventListener(document, 'click', this.clickNotParent)
  },
  destroyed() {
    removeEventListener(document, 'click', this.clickNotParent)
  },
  methods: {
    // 对应不同的策略 内地-选中国家层级
    async [`${TABTYPE.inland}_${LEVELBASE.one}`](params) {
      // 需要的设置值
      // // 选择 tab 类型
      this.selectTabType = TABTYPE.inland
      // // 选择 tab 第二层
      this.selectSubTabType = SUBTYPEBASE.province
      // // 选择  第三层
      this.selectDetailTabType = ''
      // // 第二层 展示tab
      this.subShowTabList = this.subTabBase[TABTYPE.inland]
      // 获取 第三层 具体展示 获取省份
      await this.getRateSub({
        parentDistCode: params.code,
        level: LEVELBASE.one,
      })
      const selectArea = {
        // // 国家
        countryRateCode: CountryCode.cn,
        countryCode: params.countryCode,
        regionType: TABTYPE.inland,
      }
      // // select 选择的参数
      this.selectArea = selectArea
      // // select 选择的展示的
      this.selectAreaDefault = selectArea
    },
    // 内地-选中省份层级
    async [`${TABTYPE.inland}_${LEVELBASE.two}`](params) {
      // 需要的设置值
      // // 选择 tab 类型
      this.selectTabType = TABTYPE.inland
      // // 选择 tab 第二层
      this.selectSubTabType = SUBTYPEBASE.select
      // // 选择  第三层
      this.selectDetailTabType = SUBTYPEBASE.select
      // // 第二层 展示tab
      this.subShowTabList = [
        ...this.subTabBase[TABTYPE.inland],
        ...SUBSELECTBase,
      ]
      // 获取 第三层 具体展示 获取城市
      await this.getRateSub({
        parentDistCode: params.code,
        level: LEVELBASE.two,
      })
      const selectArea = {
        // // 国家
        countryRateCode: CountryCode.cn,
        countryCode: params.countryCode,
        // 省份
        provinceCode: params.code,
        province: params.name,
        locationCode: params.rateCode,
        regionType: TABTYPE.inland,
      }
      // // select 选择的参数
      this.selectArea = selectArea
      // // select 选择的展示的
      this.selectAreaDefault = selectArea
    },
    // 内地-选中城市层级
    async [`${TABTYPE.inland}_${LEVELBASE.three}`](params) {
      // 需要的设置值
      // // 选择 tab 类型
      this.selectTabType = TABTYPE.inland
      // // 选择 tab 第二层
      this.selectSubTabType = SUBTYPEBASE.select
      // // 选择  第三层
      this.selectDetailTabType = SUBTYPEBASE.select
      // // 第二层 展示tab
      this.subShowTabList = [
        ...this.subTabBase[TABTYPE.inland],
        {
          cname: params.name,
          il8nName: '',
          type: `${params.code},${params.parentCode},${LEVELBASE.three}`,
          parentDistCode: params.parentCode,
          level: LEVELBASE.three,
          isSub: true,
          isAreaSub: true,
        },
        ...SUBSELECTBase,
      ]
      // 获取 第三层 具体展示 获取县区
      await this.getRateSub({
        parentDistCode: params.code,
        level: LEVELBASE.three,
      })
      const selectArea = {
        // // 国家
        countryRateCode: CountryCode.cn,
        countryCode: params.countryCode,
        // 省份
        provinceCode: params.parentCode,
        province: '',
        locationCode: params.rateCode,
        // 城市
        cityCode: params.code,
        city: params.name,
        regionType: TABTYPE.inland,
      }
      // select 选择的参数
      this.selectArea = selectArea
      // // select 选择的展示的
      this.selectAreaDefault = selectArea
    },
    // 港澳台-选中省份层级
    async [`${TABTYPE.hmt}_${LEVELBASE.two}`](params) {
      // 需要的设置值
      const { level, regionLevel } = params
      const relLevel = level || regionLevel
      const customType = `${params.code},${inlandCode},${relLevel}`
      // // 选择 tab 类型
      this.selectTabType = TABTYPE.hmt
      // // 选择 tab 第二层
      this.selectSubTabType = customType
      // // 选择  第三层
      this.selectDetailTabType = ''
      // // 第二层 展示tab
      this.subShowTabList = [
        ...this.subTabBase[TABTYPE.hmt],
        {
          cname: params.name,
          il8nName: '',
          type: customType,
          parentDistCode: inlandCode,
          level: relLevel,
          isSub: true,
          isAreaSub: true,
        },
      ]
      // 获取 第三层 具体展示 获取城市
      await this.getRateHMTSub({
        parentDistCode: params.code,
        level: LEVELBASE.four,
      })
      const selectArea = {
        // // 国家
        countryRateCode: CountryCode.cn,
        countryCode: inlandCode,
        // 省份
        provinceCode: params.code,
        province: params.name,
        locationCode: params.rateCode,
        regionType: TABTYPE.hmt,
      }
      // // select 选择的参数
      this.selectArea = selectArea
      // // select 选择的展示的
      this.selectAreaDefault = selectArea
    },
    // 海外--选中第一层 国家
    async [`${TABTYPE.overseas}_${LEVELBASE.one}`](params) {
      const { level, regionLevel } = params
      const relLevel = level || regionLevel
      const customType = `${params.rateCode},,${relLevel}`
      // 需要的设置值
      // // 选择 tab 类型
      this.selectTabType = TABTYPE.overseas
      // // 选择 tab 第二层
      this.selectSubTabType = customType
      // // 选择  第三层
      this.selectDetailTabType = ''
      // // 第二层 展示tab
      this.subShowTabList = [
        ...this.subTabBase[TABTYPE.overseas],
        {
          cname: params.name,
          il8nName: '',
          type: customType,
          parentDistCode: params.parentCode,
          level: relLevel,
          isSub: true,
          isAreaSub: true,
        },
      ]
      await this.getProvinceCityDistrict({
        country: params.countryCode,
        province: '',
        level: relLevel - 0 + 1,
      })
      const selectArea = {
        // // 国家
        countryRateCode: params.rateCode,
        countryCode: params.countryCode,
        countryName: params.name,
        regionType: TABTYPE.overseas,
      }
      // // select 选择的参数
      this.selectArea = selectArea
      // // select 选择的展示的
      this.selectAreaDefault = selectArea
    },
    // 海外--选中第二层 省份
    async [`${TABTYPE.overseas}_${LEVELBASE.two}`](params) {
      // 需要的设置值
      // // 选择 tab 类型
      this.selectTabType = TABTYPE.overseas
      // // 选择 tab 第二层
      this.selectSubTabType = SUBTYPEBASE.overseas
      // // 选择  第三层
      this.selectDetailTabType = ''
      // // 第二层 展示tab
      this.subShowTabList = this.subTabBase[TABTYPE.overseas]
      // 获取 第三层 具体展示 获取省份
      await this.getProvinceCityDistrict({
        country: params.countryCode,
        province: params.name,
      })
      const selectArea = {
        // // 国家
        countryRateCode: '',
        countryCode: params.parentCode,
        countryName: '',
        provinceCode: params.code,
        province: params.name,
        regionType: TABTYPE.overseas,
      }
      // // select 选择的参数
      this.selectArea = selectArea
      // // select 选择的展示的
      this.selectAreaDefault = selectArea
    },
    // 通过 缓存值或者搜索信息 设置参数 待完善
    async setCookieAndFilterParams(params) {
      // 类型 国家 省市区CODE
      const {
        regionType,
        countryCode,
        province,
        city,
        county,
        level,
        regionLevel,
      } = params
      const relLevel = level || regionLevel
      // 缓存数据
      if (regionType) {
        // 内地
        if (regionType === TABTYPE.inland) {
          await this.getCnAddrDistIdByName({
            provinceName: province,
            cityName: city,
            districtName: county,
          })
          // 设置第一层
          this.selectTabType = TABTYPE.inland
          // 获取缓存信息时候 不区分是常用市
          // 选择
          this.selectSubTabType = SUBTYPEBASE.select
          this.subShowTabList = [
            ...this.subTabBase[regionType],
            ...SUBSELECTBase,
          ]
          // 第三层数据
          this.selectDetailTabType = `${countryCode}_${county}`
        }
      } else {
        const { rateCode } = params
        const relRateCode = rateCode - 0
        // 筛选数据
        if (
          relRateCode === AreaRateCode['886'].rateCode ||
          relRateCode === AreaRateCode['852'].rateCode ||
          relRateCode === AreaRateCode['853'].rateCode
        ) {
          // 港澳台
          if (relLevel - 0 === LEVELBASE.two) {
            await this[`${TABTYPE.hmt}_${relLevel}`](params)
          }
        } else if (countryCode === inlandCode) {
          // 当为中国内地时候
          // 判断层架
          await this[`${TABTYPE.inland}_${relLevel}`](params)
        } else if (relLevel === LEVELBASE.one) {
          // 海外-先只展示国家
          await this[`${TABTYPE.overseas}_${relLevel}`](params)
        }
      }
      // 需要的设置值
      // // 选择 tab 类型
      // this.selectTabType= '',
      // // 选择 tab 第二层
      // this.selectSubTabType= '',
      // // 选择  第三层
      // this.selectDetailTabType= '',
      // // 第二层 展示tab
      // this.subShowTabList= [],
      // // 具体 全数据
      // this.detailShowList= [],
      // // 热门城市
      // this.popularCities= [],
      // // select 选择的参数
      // this.selectArea= {},
      // // select 选择的展示的
      // this.selectAreaDefault= {},
    },
    init() {
      // const cookieSelectArea = initArea
      const cookieSelectArea = this.selectAreaDefault
      // 当不存在预选择值时候
      if (!cookieSelectArea || isEmptyObject(cookieSelectArea)) {
        const selectTabList = this.selectTabList
        const defaultSelect = selectTabList[this.defaultIndex]
        // 设置默认的打开方式
        this.selectTabType = defaultSelect.type
        this.selectSubTabType = defaultSelect.defaultSub
        this.adaptSubTabShow(undefined, defaultSelect.type)
        // 默认
        this.selectArea = {
          countryCode: defaultSelect.code,
          regionType: defaultSelect.type,
        }
      } else {
        // this.setCookieAndFilterParams(cookieSelectArea)
      }
    },
    close(params = {}) {
      const isPass = this.isReSetVal(
        params.selectArea || this.selectArea,
        params.selectTabType
      )
      // 关闭之后 判断是否正确选中最后的地区
      // 如果没有选择 则不重新设置值
      if (isPass) {
        this.selectAreaDefault = Object.assign({}, this.selectArea)
        // 重新设置为初始值
        // this.selectArea = {}
      }
      this.isAreaShow = false
    },
    // 初始化
    reset() {
      // 选择 tab 类型
      this.selectTabType = ''
      // 选择 tab 第二层
      this.selectSubTabType = ''
      // 选择  第三层
      this.selectDetailTabType = ''
      // 第二层 展示tab
      this.subShowTabList = []
      // 具体 全数据
      this.detailShowList = []
      // 具体 分页展示的数据
      this.detailPageShowList = []
      // 热门城市
      this.popularCities = []
      // 过滤数据
      this.filterList = []
      this.filterText = ''
      this.isAreaShow = false
      this.isFilterShow = false
      this.selectValue = ''
      this.selectAreaDefault = {}
      this.selectArea = {}
    },
    // tab 切换时候 初始化选择
    resetSelectArea(type) {
      const selectTabType = this.selectTabType
      if (selectTabType === TABTYPE.inland) {
        if (type === SUBTYPEBASE.popCity || type === SUBTYPEBASE.province) {
          this.selectArea = Object.assign({}, this.selectArea, {
            // 省份
            provinceCode: '',
            province: '',
            // 城市
            cityCode: '',
            city: '',
            countyCode: '',
            // 地区
            county: '',
            locationCode: '',
            code: '',
          })
        }
      }
    },
    // 检测是否可以重新设置值
    isReSetVal(params = {}, type) {
      const areaSingle = this.areaSingle
      const relType = type || this.selectTabType
      const { county, city } = params
      let isPass = false
      // 内地和港澳台必须选择到地区
      // 海外选择到城市就行
      if (areaSingle) {
        isPass = true
      } else if (relType === TABTYPE.inland || relType === TABTYPE.hmt) {
        if (county) {
          isPass = true
        }
      } else if (relType === TABTYPE.overseas) {
        if (city) {
          isPass = true
        }
      }
      return isPass
    },
    // // 具体详情展示 分页设置
    // detailPagData(allList = [], page) {
    //   console.log('分页设置', allList, page)
    //   // 页码
    //   const current = page || this.detailCurrent
    //   const pageSize = this.detailPageSize
    //   const startIndex = pageSize * (current - 1)
    //   // 后数据
    //   let endIndex = pageSize * current
    //   endIndex = endIndex > allList.length ? allList.length : endIndex
    //   // 展示页面数据
    //   this.detailPageShowList = allList.slice(startIndex, endIndex)
    // },
    // 具体详情展示 改变分页
    // detailPagChange(page, pageSize) {
    //   this.detailCurrent = page
    //   this.detailPagData(this.detailShowList, page)
    // },
    // 点击城市区域外 关闭
    clickNotParent(e) {
      const parentElm = this.$refs.cityArea
      const isParant = findTargetParents(e.target, parentElm)
      if (!isParant) {
        // this.isAreaShow = false
        // this.isFilterShow = false
        this.close()
      }
    },
    // 原寄地模糊 and 目的地模糊 查询
    async getLiveOrigin(params) {
      try {
        const type = this.type
        const resApi =
          type === areaTypeBase.dest
            ? 'getLiveOriginDestsApi'
            : 'getLiveOriginApi'
        const defaultParams = Object.assign(
          { limit: 100, level: 3, originCode: this.originCode },
          params
        )
        const res = await this.$API.common[resApi](defaultParams)
        if (res) {
          // 过滤掉海外站 非国家层级
          this.filterList =
            res?.filter((item) => {
              const { countryCode, regionLevel, rateCode } = item
              const relRateCode = rateCode - 0
              if (
                countryCode !== inlandCode &&
                relRateCode !== AreaRateCode['886'].rateCode &&
                relRateCode !== AreaRateCode['852'].rateCode &&
                relRateCode !== AreaRateCode['853'].rateCode
              ) {
                if (regionLevel !== LEVELBASE.one) {
                  return false
                }
              }
              return true
            }) || []
        } else {
          this.filterList = []
        }
      } catch (e) {
        // console.log('getLiveOrigin error', e)
      }
    },
    // 目的地模糊查询
    // async getLiveOriginDests(params = {}) {
    //   try {
    //     const defaultParams = Object.assign({ limit: 100, level: 3 }, params)
    //     const res = await this.$API.common.getLiveOriginDestsApi(defaultParams)
    //     if (res) {
    //       this.filterList = res
    //     } else {
    //       this.filterList = []
    //     }
    //   } catch (e) {
    //     console.log('getLiveOriginDests error', e)
    //   }
    // },
    // 获取内地常用城市
    async getCity() {
      try {
        const res = await this.$API.common.getPopularCitiesApi()
        // const res = supportPopularRegions
        if (res) {
          this.popularCities = res.map((item) => {
            item.type = `${item.countryCode}_${item.name}`
            // 单独增加的 国家地区二字码
            item._countryRateCode = CountryCode.cn
            return item
          })
        } else {
          this.popularCities = []
        }
        // 获取到常用市区 之后 进行展示
        this.detailShowList = this.popularCities
        // 设置展示
      } catch (e) {
        // console.log('getCity error', e)
      }
    },
    // 反查查询省市信息(中国内地|港澳台)
    async getCnAddrDistIdByName(params) {
      try {
        await this.$API.common.getAddrDistIdByNameApi(params)
        // console.log('getCnAddrDistIdByName', res)
      } catch (e) {
        // console.log('getCnAddrDistIdByName error', e)
      }
    },
    // 查询省市信息(国际) 下一层级
    async getProvinceCityDistrict(params) {
      try {
        const { level, ...rest } = params
        const res = await this.$API.common.getProvinceCityDistrictApi(rest)
        if (res) {
          this.detailShowList =
            res?.map((item) => ({
              name: item,
              type: `${rest.country}_${item}_${level}`,
              countryCode: rest.country,
              province: item,
              level: level - 0,
            })) || []
        }
      } catch (e) {
        // console.log('getProvinceCityDistrict error', e)
      }
    },
    // 获取中国内地省/直辖市
    async getRateSub(params = {}) {
      try {
        const res = await this.$API.common.getRateSubApi(params)
        if (res) {
          this.detailShowList = res.map((item) => {
            if (!item.type) {
              item.type = `${item.countryCode}_${item.name}`
            }
            // 单独增加的 国家地区二字码
            item._countryRateCode = CountryCode.cn
            return item
          })
        }
      } catch (e) {
        // console.log('getRateSub error', e)
      }
    },
    // 原寄地选择港澳台
    async getRateHMTSub(params = {}) {
      try {
        const res = await this.$API.common.getRateHMTSubApi(params)
        if (res) {
          this.detailShowList = res.map((item) => {
            if (!item.type) {
              item.type = `${item.countryCode}_${item.name}`
            }
            // 单独增加的 国家地区二字码
            if (item.rateCode) {
              item._countryRateCode = AreaRateCode[item.rateCode]?.code
            }
            return item
          })
        }
      } catch (e) {
        // console.log('getRateHMTSub error', e)
      }
    },
    // 原寄地选择国际
    async getRateSubOrigins(params) {
      try {
        const res = await this.$API.common.getRateSubOriginsApi(params)
        if (res) {
          this.detailShowList = res.map((item) => {
            if (!item.type) {
              item.type = `${item.rateCode}_${item.name}`
            }
            return item
          })
          return res
        }
      } catch (e) {
        // console.log('getRateSubOrigins error', e)
      }
    },
    // 目的地选择国际
    async getRateSubDests(params) {
      try {
        Object.assign(params, { originCode: this.originCode })
        const res = await this.$API.common.getRateSubDestsApi(params)
        if (res) {
          this.detailShowList = res.map((item) => {
            if (!item.type) {
              item.type = `${item.rateCode}_${item.name}`
            }
            return item
          })
          return res
        }
      } catch (e) {
        // console.log('getRateSubDests error', e)
      }
    },
    // 综合 获取原寄送和目的地适配
    async adaptAreaGlobalSub(params) {
      const type = this.type
      try {
        const resApi =
          type === areaTypeBase.origin
            ? this.getRateSubOrigins
            : this.getRateSubDests
        await resApi(params)
      } catch (e) {
        // console.warn('adaptAreaGlobalSub error', e)
      }
    },
    // 区分第一层类型 展示第二层TAB
    adaptSubTabShow(params = {}, type) {
      const relType = type || this.selectTabType
      const selectSubTabType = this.selectSubTabType
      const subShowTabList = [...this.subShowTabList]
      const selectArea = this.selectArea
      // 当前选择 层级
      const { level, regionLevel } = params
      const relLevel = level || regionLevel
      switch (relType) {
        case TABTYPE.inland:
          // 没有值
          if (isEmptyObject(params)) {
            // 为空 展示 基础TAB数据
            if (subShowTabList.length === 0) {
              this.subShowTabList = [...this.subTabBase[relType]]
              this.selectSubTabType = this.subTabBase[relType][0].type
            }
          } else {
            // 当选择的为常用城市
            if (
              selectSubTabType === SUBTYPEBASE.popCity ||
              selectSubTabType === SUBTYPEBASE.province
            ) {
              subShowTabList.push(...SUBSELECTBase)
              this.selectSubTabType = SUBTYPEBASE.select
            } else {
              subShowTabList.splice(
                -1,
                1,
                {
                  cname: params.name,
                  il8nName: '',
                  type: `${params.distCode},${params.parentDistCode},${relLevel}`,
                  parentDistCode: params.parentDistCode,
                  level: relLevel,
                  isSub: true,
                  isAreaSub: true,
                },
                ...SUBSELECTBase
              )
              this.selectSubTabType = SUBTYPEBASE.select
            }
            this.subShowTabList = subShowTabList
          }
          break
        case TABTYPE.hmt:
          if (isEmptyObject(params)) {
            // 为空 展示 基础TAB数据
            if (subShowTabList.length === 0) {
              this.subShowTabList = [...this.subTabBase[relType]]
              this.selectSubTabType = this.subTabBase[relType][0].type
            }
          } else {
            if (selectSubTabType === SUBTYPEBASE.hmt) {
              const customType = `${params.distCode},${params.parentDistCode},${relLevel}`
              subShowTabList.push({
                cname: params.name,
                il8nName: '',
                type: customType,
                parentDistCode: params.parentDistCode,
                level: relLevel,
                isSub: true,
                isAreaSub: true,
              })
              this.selectSubTabType = customType
            } else {
              subShowTabList.splice(
                -1,
                1,
                {
                  cname: params.name,
                  il8nName: '',
                  type: `${params.distCode},${params.parentDistCode},${relLevel}`,
                  parentDistCode: params.parentDistCode,
                  level: relLevel,
                  isSub: true,
                  isAreaSub: true,
                },
                ...SUBSELECTBase
              )
              this.selectSubTabType = SUBTYPEBASE.select
            }

            this.subShowTabList = subShowTabList
          }
          break
        case TABTYPE.overseas:
          if (isEmptyObject(params)) {
            // 为空 展示 基础TAB数据
            if (subShowTabList.length === 0) {
              this.subShowTabList = [...this.subTabBase[relType]]
              this.selectSubTabType = this.subTabBase[relType][0].type
            }
          } else if (selectSubTabType === SUBTYPEBASE.overseas) {
            const customType = `${params.countryCode},,${relLevel}`
            // 选中了国家
            subShowTabList.push({
              cname: params.name,
              il8nName: '',
              type: customType,
              parentDistCode: params.parentCode,
              level: relLevel,
              isSub: true,
              isAreaSub: true,
            })
            this.selectSubTabType = customType
          } else {
            const customType = `${selectArea.countryCode},${params.name},${relLevel}`
            subShowTabList.push({
              cname: params.name,
              il8nName: '',
              type: customType,
              level: relLevel,
              isSub: true,
              isAreaSub: true,
            })
            this.selectSubTabType = customType
          }
          this.subShowTabList = subShowTabList
          break
        default:
          break
      }
    },
    // 区分接口，逐级查询数据
    // getNextAddress(params, type) {
    //   console.log('getNextAddress', params)
    //   const relType = type || this.selectTabType
    //   const adaptParams = this.getAdaptParams(params)
    //   if (relType === TABTYPE.inland || relType === TABTYPE.hmt) {
    //     this.getCnAddrDistIdByName(adaptParams)
    //   } else if (relType === TABTYPE.overseas) {
    //     this.getProvinceCityDistrict(adaptParams)
    //   }
    // },
    // 区分接口，获取第一层数据
    adaptResRegion(params = {}, type) {
      const relType = type || this.selectTabType
      const { level, parentDistCode, country, province = '' } = params
      const overseasParams = { country, province, level }
      const inlandAndHmt = { level, parentDistCode }
      switch (relType) {
        case TABTYPE.inland:
          this.getRateSub(inlandAndHmt)
          break
        case TABTYPE.hmt:
          this.getRateHMTSub(inlandAndHmt)
          break
        case TABTYPE.overseas:
          // 国际第一层展示接口区分
          if (level === LEVELBASE.one) {
            this.adaptAreaGlobalSub(inlandAndHmt)
          } else {
            this.getProvinceCityDistrict(overseasParams)
          }
          break
        default:
          break
      }
    },
    // 选择详情展示区 获取参数适配
    getAdaptParams(curItem) {
      const { provinceName, cityName, districtName, country, province } =
        curItem
      const type = this.selectTabType
      if (type === TABTYPE.inland || type === TABTYPE.hmt) {
        return {
          // 省
          provinceName,
          // 市
          cityName,
          // 区
          districtName,
        }
      } else if (type === TABTYPE.overseas) {
        return {
          // 海外-国家编码
          country,
          // 海外-省份
          province,
        }
      }
    },
    // 获取海外城市code
    async getCityCode(params) {
      const selectArea = { ...this.selectArea }
      const { countryRateCode, city, province } = params
      // 判断是否为海外地区，直接请求邮编校验去获取地区信息
      const res = await this.$API.common.getScenePostApi({
        country: countryRateCode,
        postCode: '123456',
        city,
        province,
      })
      if (res) {
        Object.assign(selectArea, {
          cityCode: res.cityCode,
          postCode: res.postCode,
        })
        this.selectArea = selectArea
      }
    },
    // 初始化 sub tab
    initSubTab(type) {
      // 展示默认的sub tab 默认选中第一个
      this.subShowTabList = this.subTabBase[type]
      if (this.subTabBase[type]) {
        this.selectSubTabType = this.subTabBase[type][0].type
      }
    },
    // 获取下一层级接口level
    getLevel(item = {}) {
      const relLevel = item.level || item.regionLevel
      const type = this.selectTabType
      let backLevel = null
      switch (type) {
        case TABTYPE.inland:
          backLevel = relLevel
          break
        case TABTYPE.hmt:
          backLevel = relLevel + 2
          break
        case TABTYPE.overseas:
          backLevel = relLevel + 1
          break
        default:
          backLevel = relLevel
          break
      }
      return backLevel
    },
    //   选择头部区域
    selectConTabs(item) {
      const { type, code } = item
      this.selectTabType = type
      // 设置选择的数据
      this.selectArea = {
        countryCode: code,
        regionType: type,
      }
      this.initSubTab(type)
    },
    // 二层选择
    selectSubTabs(type) {
      const selectTabType = this.selectTabType
      const subShowTabList = this.subShowTabList
      const index = subShowTabList.findIndex((item) => {
        return item.type === type
      })
      this.selectSubTabType = type
      // 切换初始化
      this.resetSelectArea(type)
      // 第二层 tab展示
      if (selectTabType === TABTYPE.inland) {
        if (index === 0) {
          this.initSubTab(selectTabType)
        } else {
          this.subShowTabList = subShowTabList.slice(0, index + 1)
        }
      } else {
        this.subShowTabList = subShowTabList.slice(0, index + 1)
      }
      // 当 type 为自定义类型时候
      if (type.match(',')) {
        const [code, parentDistCode, level] = type.split(',')
        const inlandAndHmtParams = {
          level: this.selectTabType === TABTYPE.hmt ? level - 0 + 1 : level,
          parentDistCode,
        }
        const overseasParams = {
          country: code,
          province: parentDistCode,
          // 自己的层级需要加1
          level: level - 0 + 1,
        }
        const realParams =
          this.selectTabType === TABTYPE.overseas
            ? overseasParams
            : inlandAndHmtParams
        this.adaptResRegion(realParams)
      }
    },
    // 三层选择
    async selectDetailTabs(item) {
      const type = this.selectTabType
      const subType = this.selectSubTabType
      const selectArea = this.selectArea
      const { level, regionLevel } = item
      const realLeval = level || regionLevel
      const selectAreaParams = Object.assign({}, selectArea)
      // 国家地区二字码
      const countryRateCode =
        selectArea.countryRateCode || item._countryRateCode
      // 请求下一个地区参数
      const getParams = {
        level: this.getLevel(item),
        parentDistCode: item.code || item.distCode,
        // 请求国际地区需要
        country: item.countryCode,
        province: item.province,
      }
      // 是否请求下一层级数据
      let isResNextLevel = true

      switch (realLeval) {
        case LEVELBASE.four:
          // 选择县区
          Object.assign(selectAreaParams, {
            // countryCode: item.countryCode,
            countyCode: item.code || item.distCode,
            county: item.name,
            locationCode: item.rateCode,
          })
          isResNextLevel = false
          // 记录数据 关闭 弹窗
          break
        case LEVELBASE.three:
          // 选择城市
          // 当选择常用市时候 需要加上省份信息
          if (subType === SUBTYPEBASE.popCity) {
            Object.assign(selectAreaParams, {
              cityCode: item.code,
              city: item.name,
              provinceCode: item.provinceCode,
              province: item.provinceName,
              countyCode: '',
              county: '',
              locationCode: item.rateCode,
              // 国家二字码
              countryRateCode,
            })
          } else {
            Object.assign(selectAreaParams, {
              cityCode: item.code,
              city: item.name,
              countyCode: '',
              county: '',
              locationCode: item.rateCode,
              // 国家二字码
              countryRateCode,
            })
          }
          // 海外只到城市
          if (type === TABTYPE.overseas) {
            isResNextLevel = false
          }
          break
        case LEVELBASE.two:
          // 选择省份
          Object.assign(selectAreaParams, {
            // countryCode: item.countryCode,
            provinceCode: item.code || item.distCode,
            province: item.name,
            cityCode: '',
            city: '',
            countyCode: '',
            county: '',
            locationCode: item.rateCode,
            // 国家二字码
            countryRateCode,
          })
          break
        case LEVELBASE.one:
          // 选择国家 目前只有海外 可选择
          Object.assign(selectAreaParams, {
            // 国家编码
            countryRateCode: item.rateCode,
            countryCode: item.countryCode,
            countryName: item.name,
            provinceCode: '',
            province: '',
            cityCode: '',
            city: '',
            countyCode: '',
            county: '',
            locationCode: item.rateCode,
          })
          break
        default:
          break
      }
      // 选中的地区信息
      this.selectArea = selectAreaParams
      // 第三层选中的
      this.selectDetailTabType = item.type
      // 获取下一层级数据
      if (isResNextLevel) {
        // this.getNextAddress(getParams)
        this.adaptSubTabShow(item)
        this.adaptResRegion(getParams)
      } else {
        // 判断是否为海外地区，直接请求邮编校验去获取地区信息
        if (type === TABTYPE.overseas) {
          await this.getCityCode(selectAreaParams)
        }
        this.close({
          selectArea: this.selectArea,
          selectTabType: type,
        })
      }
    },
    // 输入搜索
    inputSearch: debounce(function (e) {
      const val = e.target.value
      if (this.filter) {
        if (val && !val.match('/')) {
          this.isAreaShow = false
          this.isFilterShow = true
          this.filterText = val
          this.getLiveOrigin({
            query: val,
          })
        } else if (!val) {
          // this.filterText = ''
          // 清空 看是否需要重置
          this.reset()
        }
      } else {
        return false
      }
    }),
    // 筛选-选择一项进行
    async selectFilter(item) {
      this.filterText = item.name
      await this.setCookieAndFilterParams(item)
      this.isAreaShow = true
    },
    inputSelect(val) {},
    // 点击选中
    clickSelectAll(e) {
      e.currentTarget.select()
    },
    focus() {
      this.isAreaShow = true
    },
    // 分页 页码改变的回调
    paginationChange(page) {
      this.currentPage = page
    },
    // 展示当前页面数据
    paginationShow(index) {
      const currentPage = this.currentPage
      const defaultPageSize = this.defaultPageSize
      if (
        (currentPage - 1) * defaultPageSize <= index &&
        index <= currentPage * defaultPageSize - 1
      ) {
        return true
      }
      return false
    },
  },
}
