
import data from './data'
import Consign from '@/modules/templates/default/consign/index.vue'

export default {
  components: {
    Consign,
  },
  props: {
    // 落地页需改变导航
    isLanding: {
      default: false,
      type: Boolean,
    },
    guide: {
      type: Array,
      default: () => [],
    },
    method: {
      type: Array,
      default: () => [],
    },
    internationally: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      data,
    }
  },
}
