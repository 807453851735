// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/icons/quarter-checked.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".radio-slide[data-v-01e7bff1],.radio-slide-item[data-v-01e7bff1]{display:inline-block}.radio-slide-item[data-v-01e7bff1]{padding:0.08rem 0.2rem;line-height:1;margin-right:0.08rem;border-radius:0.02rem;cursor:pointer;border:0.01rem solid #dedede}.radio-slide-item[data-v-01e7bff1]:last-child{margin-right:0}.radio-slide-item.active[data-v-01e7bff1]{color:#e31d33;border-color:#e31d33;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") -0.01rem -0.02rem no-repeat;background-size:0.16rem 0.16rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
