
import { mapState } from 'vuex'
import SupportTop from './components/support-top.vue'
import gjUtmConfig from '@/utils/gjUtm'
import allUrls from '@/utils/config'
import { throttle } from '@/utils/utils'
import { ShippingShippingLinkClick } from '@/reporter/track'

export default {
  components: { SupportTop },
  props: {
    // 是否内嵌落地页
    isLanding: {
      type: Boolean,
      default: false,
    },
    clientData: {
      type: Object,
      default: () => ({}),
    },
    guide: {
      type: Array,
      default: () => [],
    },
    method: {
      type: Array,
      default: () => [],
    },
    internationally: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      lang: this.$route.params.lang,
      selectDetails: {},
      selectSteps: {},
      currentStep: 0,
      guideContentLocal: [],
    }
  },
  head() {
    return {
      title: this.$t(`navs.shipService`) + this.$t('navs.suffixName'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('descriptions.consign'),
        },
      ],
    }
  },
  computed: {
    ...mapState({
      locale: (state) => state.locale,
      resizeInfo: (state) => state.resizeInfo,
    }),
    language() {
      return this.$route.params.lang
    },
    routeName() {
      return this.$route.name
    },
    // 是否为综合落地页的寄件页
    isMainLandingRoute() {
      return this.routeName === 'site-lang-landing-consign'
    },
    // 获取当前微信小程序图片
    getWxappPic() {
      return this.isLanding
        ? this.isMainLandingRoute
          ? require('~/assets/img/landing/apps/main-consign-miniapp.png')
          : require('~/assets/img/landing/apps/express-consign-miniapp.png')
        : require('~/assets/img/consign/qrcode_2.png')
    },
    // 获取当前顺丰App图片
    getSFappPic() {
      return this.isLanding
        ? this.isMainLandingRoute
          ? require('~/assets/img/landing/apps/main-consign-sfapp.png')
          : require('~/assets/img/landing/apps/express-consign-sfapp.png')
        : this.resizeInfo.isMobile
        ? require('~/assets/img/intl-app-logo.png')
        : require('~/assets/img/consign/qrcode_3.png')
    },
    routeSite() {
      return this.$route.params.site
    },
    // 获取正确的国际推广码
    getGjUtm() {
      return this.isLanding
        ? gjUtmConfig.getCurrentLandingKey(this)
        : gjUtmConfig.sfIntlOsSs2[this.routeSite]
    },
    methodList() {
      return [
        {
          banner: require('~/assets/img/service-support/shipping_type_1.png'),
          title: this.$t('serviceSupport.consigns.type1Title'),
          subTitle: this.$t('serviceSupport.consigns.descptionTitle1'),
          imgSrc: this.resizeInfo.isMobile
            ? ''
            : require('~/assets/img/consign/img_1.png'),
          imgDes: this.resizeInfo.isMobile
            ? ''
            : this.$t('serviceSupport.consigns.international'),
          linkButton: this.$t('serviceSupport.consigns.shippingBtn'),
          link: this.clientData.sendLink.href,
          descriptionCode: 'iuop_description',
        },
        {
          banner: require('~/assets/img/service-support/shipping_type_2.png'),
          title: this.$t('serviceSupport.consigns.type2Title'),
          subTitle: this.$t('serviceSupport.consigns.descptionTitle2'),
          imgSrc: this.getWxappPic,
          imgDes: this.$t('serviceSupport.consigns.wechatCode'),
          descriptionCode: 'miniapp_description',
        },
        {
          banner: require('~/assets/img/service-support/shipping_type_3.png'),
          title: this.$t('serviceSupport.consigns.type3Title'),
          subTitle: this.resizeInfo.isMobile
            ? this.$t('serviceSupport.consigns.descptionTitle3Mobile')
            : this.$t('serviceSupport.consigns.descptionTitle3'),
          imgSrc: this.getSFappPic,
          imgRadius: !!this.resizeInfo.isMobile,
          imgDes: this.resizeInfo.isMobile
            ? ''
            : this.$t('serviceSupport.consigns.internationalAPP'),
          linkButton: this.resizeInfo.isMobile
            ? this.$t('button.download')
            : '',
          link: this.resizeInfo.isMobile ? allUrls.ciosActivity : '',
          descriptionCode: 'app_description',
        },
      ]
    },
  },
  mounted() {
    this.addListener()
    this.getGuideContent()
    // 打开满意度
    this.$nextTick(() => {
      this.$refs.feedbackRef.openFeedback()
    })
  },
  destroyed() {
    this.removeListener()
  },
  methods: {
    track(property) {
      ShippingShippingLinkClick(property)
    },
    addListener() {
      window.addEventListener(
        'scroll',
        (ev) => {
          this.$nextTick(() => {
            const header = document.getElementById('header')
            const top = header?.offsetHeight || 0
            const nav = this.$refs.guideNav?.[0]
            const container = this.$refs.guideContainer?.[0]
            if (
              window.scrollY > 800 &&
              window.scrollY > container?.offsetTop &&
              window.scrollY <
                container?.offsetTop +
                  container?.offsetHeight -
                  nav?.offsetHeight
            ) {
              nav.classList.add('fixed')
              nav.style = `height: auto;max-height: calc(100vh - ${top}px);top: ${top}px`
              // this.setStepWithScroll(scrollY)
            } else {
              nav?.classList.remove('fixed')
            }
          })
        },
        false
      )
    },
    removeListener() {
      window.removeEventListener('scroll', () => {}, false)
    },
    getGuideContent() {
      const list = (this.$refs.guideContent || []).reduce((total, it) => {
        total.push(it.offsetTop - 16)
        return total
      }, [])
      this.guideContentLocal = list
    },
    setStepWithScroll(scrollY) {
      for (let i = 0; i < this.guideContentLocal.length; i++) {
        if (
          this.guideContentLocal[i] < scrollY &&
          this.guideContentLocal[i + 1] > scrollY
        ) {
          return (this.currentStep = i)
        }
      }
    },
    getMethodHtml(code) {
      return this.method.find((it) => it.code === code)?.content || ''
    },
    openDetail(index) {
      if (this.selectDetails[index]) {
        this.$set(this.selectDetails, index, false)
      } else {
        this.$set(this.selectDetails, index, true)
      }
    },
    openStep(index) {
      if (this.selectSteps[index]) {
        this.$set(this.selectSteps, index, false)
      } else {
        this.$set(this.selectSteps, index, true)
      }
    },
    changeStep(it, index) {
      this.currentStep = index
      const container = this.$refs.guideContainer[0]
      const content = this.$refs.guideContent[index]
      window.scrollTo({
        top:
          content.offsetTop + container.offsetHeight - container.scrollHeight,
        behavior: 'smooth',
      })
    },
    eventCatch: throttle(function (ev) {
      if (ev.target.localName === 'a') {
        this.track({
          url: ev.target.href,
          keyword: ev.target.textContent,
        })
      } else if (ev.target.parentElement.localName === 'a') {
        this.track({
          url: ev.target.parentElement.href,
          keyword: ev.target.textContent,
        })
      }
    }, 1500),
  },
}
