
export default {
  model: {
    prop: 'modelValue',
    event: 'updateValue'
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    message: {
      type: String,
      default: ""
    },
    cancelText: {
      type: String,
      default: ""
    },
    confirmText: {
      type: String,
      default: ""
    },
    showConfirm: {
      type: Boolean,
      default: true
    },
    showCancel: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    visible: {
      get() {
        return this.modelValue
      },
      set(v) {
        this.$emit('updateValue', v)
      }
    }
  },
  methods: {
    onCancelClick() {
      this.visible = false
      this.$emit('cancel', true)
    },
    onConfirmClick() {
      this.visible = false
      this.$emit('confirm', true)
    }
  }
}
