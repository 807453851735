
export default {
  name: 'ItemImg',
  props: {
    alt: {
      type: String,
      default: '',
    },
    ossAccessWebPath: {
      type: String,
      default: '',
    },
    picKey: {
      type: String,
      default: '',
    },
    errorImg: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      imgLink: '',
    }
  },
  async created() {
    try {
      await this.getImgUrl(this.ossAccessWebPath)
    } catch (error) {
      console.log(error)
    }
  },
  methods: {
    getFormatUrl(ossAccessWebPath) {
      const picKey =
        ossAccessWebPath.replace(
          /.+\/AUTH_IBU-OWT-CORE\/cms\/([a-z0-9A-Z]{10,}\.[a-zA-Z]{3,5})/,
          '$1'
        ) || ''
      const getUrl =
        ossAccessWebPath.replace(
          /http(s)?:\/\/[a-z-.0-9]+\.com(.*)/,
          '/oss$2'
        ) || '/oss/' + this.picKey
      return { picKey, getUrl }
    },
    async getImgUrl(ossAccessWebPath) {
      const { getUrl } = this.getFormatUrl(ossAccessWebPath)
      try {
        const token =
          (await this.$API.common.getImgLinkToken({ picKey: this.picKey })) ||
          ''
        const url = (await this.$axios.get(getUrl, {
          responseType: 'blob',
          headers: { 'X-Auth-Token': token },
        })) || { data: '' }
        if (process.client) {
          this.imgLink = url.data
            ? URL.createObjectURL(new Blob([url.data])) || ''
            : ''
        }
      } catch (e) {
        this.error()
      }
    },
    error() {
      this.imgLink = this.errorImg
    },
  },
}
