const data = {
  logistics: [
    {
      navTitle: 'modules.logisticsService.productList.express', // 国际快递服务
      children: [
        {
          title: 'modules.logisticsService.productList.fast',
          subTitle: ['product.productDelivery.standardExpressCE'],
          local: '1',
          link: {
            path: '/product/fast',
            query: { isProduct: 'product' },
          },
          imgBg: {
            url: require('~/assets/img/logistics/fast.jpg'),
            alt: 'Standard Express',
          },
        },
        {
          title: 'modules.logisticsService.productList.discts',
          subTitle: ['product.productDelivery.economyExpressCI'],
          local: '2',
          link: {
            path: '/product/discts',
            query: { isProduct: 'product' },
          },
          imgBg: {
            url: require('~/assets/img/logistics/discts.jpg'),
            alt: 'Economy Express',
          },
        },
      ],
    },
    {
      navTitle: 'modules.logisticsService.productList.commerce', // 跨境电商服务
      children: [
        {
          title: 'modules.logisticsService.productList.delivery', // 国际电商专递
          subTitle: [
            'product.productDelivery.ecommerceExpressCI1',
            'product.productDelivery.ecommerceExpressCI2',
          ],
          local: '3',
          link: {
            path: '/product/delivery',
            query: { isProduct: 'product' },
          },
          imgBg: {
            url: require('~/assets/img/logistics/delivery.jpg'),
            alt: 'E-Commerce Express',
          },
        },
        {
          title: 'modules.logisticsService.productList.parcel', // 国际小包
          subTitle: ['product.productDelivery.e-ParcelCI'],
          local: '4',
          link: {
            path: '/product/parcel',
            query: { isProduct: 'product' },
          },
          imgBg: {
            url: require('~/assets/img/logistics/parcel.jpg'),
            alt: 'E-Parcel',
          },
        },
        {
          title: 'modules.logisticsService.productList.warehouse', // 海外仓
          subTitle: ['product.productDelivery.overSeaCI'],
          local: '5',
          link: {
            path: '/product/warehouse',
            query: { isProduct: 'product' },
          },
          imgBg: {
            url: require('~/assets/img/logistics/warehouse.jpg'),
            alt: 'Overseas Warehouse',
          },
        },
        {
          title: 'modules.logisticsService.productList.sfbuy', // 集货转运(SFBuy)
          subTitle: ['product.productDelivery.SFBuyCI'],
          local: '6',
          link: {
            path: '/product/sfbuy',
            query: { isProduct: 'product' },
          },
          imgBg: {
            url: require('~/assets/img/logistics/sfbuy.jpg'),
            alt: 'SFBuy',
          },
        },
      ],
    },
  ],
  services: [],
  surcharge: [],
}

export default data
