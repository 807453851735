import { pages as pageCn } from '@/modules/site/cn'
import { pages as pageSg } from '@/modules/site/sg'
import { pages as pageMy } from '@/modules/site/my'
import { pages as pageTh } from '@/modules/site/th'
import { pages as pageId } from '@/modules/site/id'
import { pages as pagePh } from '@/modules/site/ph'
import { pages as pageVn } from '@/modules/site/vn'
import { pages as pageJp } from '@/modules/site/jp'
import { pages as pageAu } from '@/modules/site/au'

export default {
  cn: pageCn,
  sg: pageSg,
  my: pageMy,
  th: pageTh,
  id: pageId,
  ph: pagePh,
  vn: pageVn,
  jp: pageJp,
  au: pageAu,
}
