
import { mapState } from 'vuex'
import data from './data'
import SupportNav from '@/components/page/support-nav.vue'
import SupportTop from '@/components/page/support-top.vue'
import Pc from './components/pc.vue'
import Mb from './components/mb.vue'

export default {
  components: {
    Pc,
    Mb,
    SupportNav,
    SupportTop,
  },
  layout: 'base',
  data() {
    return {
      data,
    }
  },
  computed: {
    ...mapState({
      locale: (state) => state.locale,
      site: (state) => state.site,
      isMobile: (state) => state.isMobile,
    }),
  },
  methods: {},
}
