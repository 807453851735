import { specialSite } from '@/utils/const'

const mixin = {
  layout({ route }) {
    const {
      params: { site },
    } = route
    return site === 'jp' ? 'japan' : specialSite.includes(site) ? 'default' : 'base'
  },
}
export default mixin
