
export default {
  props: {
    title: {
      type: String,
      default: () => {
        return ''
      },
    },
  },
}
