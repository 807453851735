
import { mapState } from 'vuex'
import Banner from './components/banner.vue'
import Panel from './components/panel.vue'
import Promotion from './components/promotion.vue'

export default {
  components: {
    Banner,
    Panel,
    Promotion
  },
  props: {
    clientData: {
      type: Object,
      default: () => ({}),
    },
    announcementSidebar: {
      type: Object,
      default: () => ({}),
    },
    bannerList: {
      type: Array,
      default: () => [],
    },
    announcementList: {
      type: Array,
      default: () => [],
    },
    newsList: {
      type: Array,
      default: () => [],
    },
    popularize: {
      type: Array,
      default: () => [],
    },
    pageInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  head() {
    return {
      title: this.$t(`modules.home.pageTitle`) + this.$t('navs.suffixName'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('descriptions.home'),
        },
      ],
    }
  },
  computed: {
    ...mapState({
      counter: (state) => state.counter,
      resizeInfo: (state) => state.resizeInfo,
      isHdFtMobile: (state) => state.isHdFtMobile,
    }),
  },
}
