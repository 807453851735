
export default {
  name: 'CfSelect',
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    mode: {
      type: String,
      default: 'multiple',
    },
    height: {
      type: String,
      default: '100%',
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    searchTitle: {
      type: String,
      default: '',
    },
    searchPlaceholder: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
    noData: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      show: false,
      list: [],
      searchValue: '',
    }
  },
  computed: {
    selectList: {
      get() {
        return this.modelValue
      },
      set(v) {
        return this.$emit('update:modelValue', v)
      },
    },
    getList: {
      get() {
        return this.list
      },
      set(v) {
        this.list = v
      },
    },
    indexList() {
      return this.list
    },
  },
  watch: {
    options: {
      handler(nv) {
        this.list = nv
      },
      immediate: true,
    },
  },
  methods: {
    isActive(it) {
      return this.selectList.some((select) => select.value === it.value)
    },
    showActionSheet() {
      this.show = true
    },
    onSearchInput(val) {
      if (val) {
        this.list = this.options.filter(
          (it) => it.label.includes(val) || it.value.includes(val)
        )
      } else {
        this.list = this.options
      }
    },
    onSelectCell(cell) {
      if (this.mode !== 'multiple') {
        this.selectList.length = 0
      }
      this.onDelOrAddCell(cell || '')
    },
    onDelOrAddCell(cell) {
      if (!this.selectList.some((it) => it.value === cell.value)) {
        this.selectList.push(cell)
      } else {
        const index = this.selectList.map((it) => it.value).indexOf(cell.value)
        this.selectList.splice(index, 1)
      }
      this.$emit('onSelect', cell)
    },
    onSelected() {
      this.show = false
    },
    onActionClosed() {
      this.searchValue = ''
      this.onSearchInput(this.searchValue)
    },
  },
}
