
import { mapState } from 'vuex'
import TabContainer1 from './components/-TabContainer1'
import TabContainer1Mb from './components/mobile/TabContainer1Mb.vue'
import TabContainer2 from './components/-TabContainer2'
import TabContainer3 from './components/-TabContainer3'
import TabBar from './components/-TabBar'
import HeadBanner from '~/components/common/HeadBanner'
import LayoutMixin from '@/mixins/layout'

export default {
  components: {
    TabBar,
    HeadBanner,
    TabContainer1,
    TabContainer1Mb,
    TabContainer2,
    TabContainer3,
  },
  mixins: [LayoutMixin],
  props: {
    clientData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      curPos: 0,
    }
  },
  head() {
    return {
      title: this.$t(`navs.logisticsService`) + this.$t('navs.suffixName'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('descriptions.product'),
        },
      ],
    }
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
    }),
    getTabBar() {
      return this.isMobile ? 'TabBarMb' : 'TabBar'
    },
    getTabContainer1() {
      return this.isMobile ? 'TabContainer1Mb' : 'TabContainer1'
    },
  },
  watch: {
    $route() {
      this.updateHash()
    },
    deep: true,
    immediate: true,
  },
  mounted() {
    this.updateHash()
  },
  methods: {
    updateHash() {
      if (process.client) {
        // let key = ''
        if (this.$route.hash.includes('increment')) {
          this.curPos = 1
          // key = 'value_added_service_page|增值服务页面'
        } else if (this.$route.hash.includes('addition')) {
          this.curPos = 2
          // key = 'surcharge_page|附加费页面'
        } else {
          this.curPos = 0
          // key = 'logistics_service_page|物流产品页面'
        }
      }
    },
    onItemTabbarClick(e) {
      this.curPos = Number(e.pos)
      if (this.curPos === 0) {
        window.location.hash = '#product'
      } else if (this.curPos === 1) {
        window.location.hash = '#increment'
      } else if (this.curPos === 2) {
        window.location.hash = '#addition'
      }
    },
  },
}
