
import RadioSlide from '@/components/radio/slide.vue'
import CtButton from '@/components/button/index.vue'
import { emailReg } from '@/utils/const'
import mixin from '@/mixins/common'
import { InvoiceApplySuccess } from '@/reporter/track'
import CtAlert from '@/components/message/alert.vue'

export default {
  components: {
    CtAlert,
    CtButton,
    RadioSlide
  },
  mixins: [mixin],
  props: {
    parentForm: {
      type: Object,
      default: () => ({})
    },
    result: {
      type: Object,
      default: () => ({})
    },
    paymentTypeCode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      activeList: [],
      custType: '1',
      form: {},
      current: 0,
      timer: null,
      loading: false,
      disabled: true,
      feeLoading: false,
      resultObj: {},
      failStatus: false,
      successStatus: false,
      failMessage: '',
      radioList: [
        {
          label: this.$t('serviceSupport.querySupport.posting_stds.individual'),
          value: '1'
        },
        {
          label: this.$t('serviceSupport.querySupport.posting_stds.company'),
          value: '2'
        }
      ],
      formMap: {
        user: [
          {
            prop: 'name',
            label: this.$t('common.user.name'),
            placeholder: this.$t('common.form.pleaseInput')
          },
          {
            prop: 'address',
            label: this.$t('common.user.address'),
            placeholder: this.$t('common.form.pleaseInput')
          },
          {
            prop: 'email',
            label: this.$t('common.user.email'),
            placeholder: this.$t('common.form.pleaseInput')
          }
        ],
        company: [
          {
            prop: 'name',
            label: this.$t('common.company.name'),
            placeholder: this.$t('common.form.pleaseInput')
          },
          {
            prop: 'taxCode',
            label: this.$t('common.company.taxCode'),
            placeholder: this.$t('common.form.pleaseInput')
          },
          {
            prop: 'address',
            label: this.$t('common.company.address'),
            placeholder: this.$t('common.form.pleaseInput')
          },
          {
            prop: 'email',
            label: this.$t('serviceSupport.invoice.email'),
            placeholder: this.$t('common.form.pleaseInput')
          }
        ]
      },
      rules: {
        user: {
          name: [
            { required: true, message: this.$t('common.form.enterLabel', { label: this.$t('common.user.name') }), trigger: 'blur' },
            { max: 255, message: this.$t('common.form.lessThan', { num: 255 }), trigger: 'blur' },
          ],
          address: [
            { required: true, message: this.$t('common.form.enterLabel', { label: this.$t('common.user.address') }), trigger: 'blur' },
            { max: 255, message: this.$t('common.form.lessThan', { num: 255 }), trigger: 'blur' },
          ],
          email: [
            { required: true, message: this.$t('common.form.enterLabel', { label: this.$t('common.user.email') }), trigger: 'blur' },
            { max: 255, message: this.$t('common.form.lessThan', { num: 255 }), trigger: 'blur' },
            { pattern: emailReg, message: this.$t('common.form.enterValid', { label: this.$t('common.user.email') }), trigger: 'blur' }
          ]
        },
        company: {
          name: [
            { required: true, message: this.$t('common.form.enterLabel', { label: this.$t('common.company.name') }), trigger: 'blur' },
            { max: 255, message: this.$t('common.form.lessThan', { num: 255 }), trigger: 'blur' },
          ],
          address: [
            { required: true, message: this.$t('common.form.enterLabel', { label: this.$t('common.company.address') }), trigger: 'blur' },
            { max: 255, message: this.$t('common.form.lessThan', { num: 255 }), trigger: 'blur' },
          ],
          email: [
            { required: true, message: this.$t('common.form.enterLabel', { label: this.$t('serviceSupport.invoice.email') }), trigger: 'blur' },
            { max: 255, message: this.$t('common.form.lessThan', { num: 255 }), trigger: 'blur' },
            { pattern: emailReg, message: this.$t('common.form.enterValid', { label: this.$t('serviceSupport.invoice.email') }), trigger: 'blur' }
          ],
          taxCode: [
            { required: true, message: this.$t('common.form.enterLabel', { label: this.$t('serviceSupport.invoice.email') }), trigger: 'blur' },
            { max: 14, message: this.$t('common.form.lessThan', { num: 14 }), trigger: 'blur' },
          ]
        }
      }
    }
  },
  computed: {
    formKey() {
      return this.custType === '1' ? 'user' : 'company'
    },
    formList() {
      return this.formMap[this.formKey] || []
    },
    currency() {
      return this.result.waybillList?.[0]?.feeTypeList?.[0]?.currency || 'VND'
    }
  },
  watch: {
    form: {
      handler() {
        this.isButtonDisabled()
      },
      deep: true
    },
    result: {
      handler(nv) {
        this.resultObj = nv
        this.initActiveList()
      },
      deep: true,
      immediate: true
    },
  },
  methods: {
    initActiveList() {
      this.activeList = this.result?.waybillList?.map((it) => it.waybillNo) || []
    },
    initResultObj() {
      this.resultObj = {
        subTotal: 0,
        totalAmt: 0,
        vatTotal: 0,
        waybillList: []
      }
    },
    isEnterAllData() {
      for (const i in this.formList) {
        if (!this.form[this.formList[i].prop]) {
          this.disabled = true
          return false
        }
      }
      return true
    },
    isButtonDisabled() {
      this.$nextTick(() => {
        const isEnterAllData = this.isEnterAllData()
        if (isEnterAllData) {
          this.$refs.form.validate((valid) => {
            if (valid) {
              this.disabled = false
            } else {
              this.disabled = true
            }
          })
        }
      })
    },
    onFeeDetailClick(id) {
      this.delayed(id)
    },
    onRadioChange() {
      this.disabled = true
      this.$refs.form.resetFields()
      const formData = this.formList.reduce((total, it) => {
        total[it.prop] = ''
        return total
      }, {})
      this.form = formData
    },
    onFailConfirm() {
      this.failStatus = false
      this.onSubmitButtonClick()
    },
    isSuccessResult() {
      this.successStatus = true
      const timer = setTimeout(() => {
        clearTimeout(timer)
        this.successStatus = false
        this.$emit('success', true)
      }, 2000)
      InvoiceApplySuccess()
    },
    isFailResult(msg = '') {
      this.failMessage = msg
      this.failStatus = true
    },
    isErrorResult(msg) {
      this.showCustomsTips({
        title: this.$t('common.tips.warmReminder'),
        message: msg,
        okText: this.$t('button.retry'),
        onOk: () => {
          this.onSubmitButtonClick()
        }
      })
    },
    onSubmitButtonClick() {
      const params = {
        requestId: this.result.requestId,
        custType: this.custType,
        custInfo: this.form,
        paymentTypeCode: this.paymentTypeCode,
        waybillNos: this.activeList
      }
      this.setLoading()
      this.$API.support.getVnInvoiceApply(params).then((res) => {
        const { succ, result, msg } = res
        if (succ === 'ok') {
          if (result === '01') {
            this.isSuccessResult()
          } else {
            this.isFailResult()
          }
        } else {
          this.isErrorResult(msg)
        }
      }).finally(() => {
        this.removeLoading()
      })
    },
    delayed(id) {
      if (this.current === 0) {
        this.current++
        this.timer = setTimeout(() => {
          clearTimeout(this.timer)
          this.current = 0
        }, 60000)
        this.calculate(id)
      } else if (this.current < 20) {
        this.calculate(id)
        this.current++
      } else {
        this.showToast({
          message: this.$t('common.tips.frequent')
        })
      }
    },
    calculate(id) {
      const index = this.activeList.indexOf(id)
      if (index < 0) {
        this.activeList.push(id)
      } else {
        this.activeList.splice(index,1)
      }
      this.getTotalPrice()
    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min)
    },
    getTotalPrice() {
      if (this.activeList.length > 0) {
        const params = {
          ...this.parentForm,
          waybillNos: this.activeList,
        }
        this.feeLoading = true
        this.$API.support
          .queryVnInvoiceNoSlider(params)
          .then((res) => {
            if (res) {
              this.resultObj = res?.result || {}
            }
          })
          .finally(() => {
            this.feeLoading = false
          })
      } else {
        this.initResultObj()
      }
    },
    setLoading() {
      this.loading = true
      this.$toast.loading({
        duration: 0,
        loadingType: 'spinner',
        forbidClick: true,
        message: this.$t('common.tips.waiting'),
      });
    },
    removeLoading() {
      this.loading = false
      this.$toast.clear()
    },
    showCustomsTips({title, message = '', okText, onOk = () => {}, onCancel = () => {}}) {
      this.showConfirm({
        title: title,
        message: message,
        okText: okText,
        onOk: onOk,
        onCancel: onCancel
      })
    }
  }
}
