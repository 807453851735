
import NavTitle from './-NavTitle'

export default {
  name: 'TabContainer1',
  components: {
    NavTitle,
  },
  props: {
    curPos: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      pr1: [],
    }
  },
  computed: {
    dataList() {
      return [
        {
          navTitle: 'modules.logisticsService.increment',
          children: this.pr1,
        },
      ]
    },
  },
  watch: {
    curPos: {
      handler(nv) {
        this.getDataSwitch(nv)
      },
      immediate: true,
    },
  },
  methods: {
    getDataSwitch(curPos) {
      if (curPos === 1 && !this.pr1.length) {
        this.getLogisticsServiceMain()
      }
    },
    getLogisticsServiceMain() {
      this.$API.product.getLogisticsServiceMain({ type: 2 }).then((res) => {
        this.pr1 = res?.result || []
      })
    },
  },
}
