
import { mapState } from 'vuex'
import NavTitle from './-NavTitle'
import OssImg from '@/components/OssImg.vue'

export default {
  name: 'TabContainer1',
  components: {
    NavTitle,
    OssImg,
  },
  props: {
    curPos: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      pr1: [],
      pr2: [],
      activeTab: '',
    }
  },
  computed: {
    ...mapState({
      site: (state) => state.site,
    }),
    list1() {
      return {
        navTitle: 'modules.logisticsService.productList.express',
        children: this.pr1,
      }
    },
    getList1Name() {
      return this.pr1.map((item) => {
        return item.name
      })
    },
    getList1Description() {
      return this.pr1.map((item) => {
        return item.description
      })
    },
    getList1ProductDescription() {
      return this.pr1.map((item) => {
        return item.productDescription
      })
    },
    getList1Url() {
      return this.pr1.map((item) => {
        return item.url
      })
    },
    list2() {
      return {
        navTitle: 'modules.logisticsService.productList.commerce',
        children: this.pr2,
      }
    },
  },
  watch: {
    curPos: {
      handler(nv) {
        this.getDataSwitch(nv)
      },
      immediate: true,
    },
  },
  methods: {
    isLegalWebsite(url) {
      const reg = /(http(s)?:\/\/)?[a-z0-9A-Z]+\.(com|cn|net)(.+)?/
      if (reg.test(url)) {
        return url
      } else {
        return '#'
      }
    },
    getDataSwitch(curPos) {
      if (curPos === 0 && (!this.pr1.length || !this.pr2)) {
        this.getLogisticsServiceMain()
      }
    },
    getLogisticsServiceMain() {
      this.$API.product.getLogisticsServiceMain({ type: 1 }).then((res) => {
        this.pr1 = res?.result?.filter((it) => it.productType === 1) || []
        this.pr2 = res?.result?.filter((it) => it.productType === 2) || []
      })
    },
    isActive(item, index) {
      return this.activeTab === item.id || (!this.activeTab && index === 0)
    },
  },
}
