// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/about/pc_text_bg.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media screen and (min-width:751px){.banner[data-v-4954b7a7]{position:relative;width:100%;min-width:100%}.banner img[data-v-4954b7a7]{width:100%;height:4.5rem}.banner .title-wrapper[data-v-4954b7a7]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%;position:absolute;width:4.25rem;height:3.5rem;top:0.5rem;left:3%;margin:0 auto}.banner .title-wrapper h1[data-v-4954b7a7]{height:0.52rem;font-size:0.36rem;font-weight:500;color:#000;line-height:0.52rem;display:flex;align-items:center;height:100%;width:100%;top:unset;position:unset;left:unset;margin:0 0 0 1.43rem}.banner .title-wrapper h1[data-v-4954b7a7]:before{content:\"\";width:0.08rem;height:0.32rem;background:#e9212e;margin-right:0.25rem;border-radius:0.04rem}}@media screen and (max-width:750px){.banner[data-v-4954b7a7]{position:relative;width:100%;min-width:100%}.banner img[data-v-4954b7a7]{width:100%;height:2.4rem}.banner .title-wrapper[data-v-4954b7a7]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%;position:absolute;width:3.75rem;height:2.5rem;top:-0.4rem;left:-0.4rem;margin:0 auto}.banner .title-wrapper h1[data-v-4954b7a7]{font-size:0.4rem;font-weight:500;color:#000;line-height:0.52rem;display:flex;align-items:center;height:100%;width:100%;top:0.16rem;position:absolute;left:1.2rem;margin:0}.banner .title-wrapper h1[data-v-4954b7a7]:before{content:\"\";width:0.08rem;height:0.32rem;background:#e9212e;margin-right:0.25rem;border-radius:0.04rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
