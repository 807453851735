import { render, staticRenderFns } from "./-TabBar.vue?vue&type=template&id=56677e5b&scoped=true&"
import script from "./-TabBar.vue?vue&type=script&lang=js&"
export * from "./-TabBar.vue?vue&type=script&lang=js&"
import style0 from "./-TabBar.vue?vue&type=style&index=0&id=56677e5b&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56677e5b",
  null
  
)

export default component.exports