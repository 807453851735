
import { mapState } from 'vuex'
import PcForm from './pc.vue'
import MoForm from './mo.vue'
export default {
  name: 'SfForm',
  components: {
    PcForm,
    MoForm,
  },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      resizeInfo: (state) => state.resizeInfo,
    }),
  },
  methods: {},
}
