
import { mapState } from 'vuex'
import { formatTimeWithMoment } from '@/utils/date'

export default {
  props: {
    path: {
      type: String,
      default: ''
    },
    track: {
      type: String,
      default: ''
    },
    promotionList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({}),
  },
  methods: {
    formatTimeWithMoment,
  }
}
