
export default {
  props: {
    // 组件样式
    styleObject: {
      type: Object,
      required: false,
      default: () => {},
    },
    // 文本样式
    styleTitleObject: {
      type: Object,
      required: false,
      default: () => {},
    },
    src: {
      type: String,
      default: () => {
        return ''
      },
    },
    title: {
      type: String,
      default: () => {
        return ''
      },
    },
  },
  data() {
    return {}
  },
  methods: {
    // onClickTop() {
    //   window.scrollTo({
    //     left: 0,
    //     top: 0,
    //     behavior: 'smooth',
    //   })
    // },
  },
}
