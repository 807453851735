
export default {
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    mode: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: [String, Array],
      default: undefined,
    },
    placeholder: {
      type: String,
      default: '',
    },
    search: {
      type: Function,
      default: () => {},
    },
    change: {
      type: Function,
      default: () => {},
    },
    // blur: {
    //   type: Function,
    //   default: () => {},
    // },
    defaultActiveFirstOption: {
      type: Boolean,
      default: false,
    },
    notFoundContent: {
      type: [String, Object],
      default: '',
    },
  },
  data() {
    return {
      dropdownStyle: {
        zIndex: '10',
      },
    }
  },
  computed: {
    selectedValue: {
      get() {
        return this.modelValue
      },
      set(v) {
        return this.$emit('update:modelValue', v)
      },
    },
    suffixIcon() {
      return <svg-icon icon-name="chevronDown" class="arrow-icon" />
    },
    // notFoundContent() {
    //   return !this.modelValue ? '' : undefined
    // },
  },
  methods: {
    blur() {
      this.$emit('blur', this.modelValue)
    },
    focus() {
      this.$emit('focus', this.modelValue)
    },
  },
}
