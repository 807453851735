
import { mapState } from 'vuex'
import CONFIG from '@/utils/config'
import { specialSite } from '@/utils/const/countryLang'
import FeedBack from '@/utils/feedback'
import { mobilePx } from '@/utils/constBase'
import { channelCodeMap } from '@/utils/const/feedback'

// 组件码表
const componentCodeMap = {
  freight_aging: 'satisfaction_001', // 运费时效
  receiving_standard: 'satisfaction_002', // 收寄标准
  waybill: 'satisfaction_003', // 查运单
  uploadPhotos: 'satisfaction_004', // 上传清关图片
  infromaton: 'satisfaction_005', // 清关申报信息收集
  consign: 'satisfaction_006', // 我要寄件
}

// 场景码
const sceneCodeMap = {
  freight_aging: 'S200001', // 运费时效
  receiving_standard: 'S200002', // 收寄标准
  waybill: 'S200003', // 查运单
  uploadPhotos: 'S200004', // 上传清关图片
  infromaton: 'S200005', // 清关申报信息收集
  consign: 'S200006', // 我要寄件
}

export default {
  name: 'CfFeedBack',
  props: {
    // 场景编码
    sceneCode: {
      required: true,
      type: String,
      default: '',
    },
  },
  data() {
    return {
      fb: null,
    }
  },
  computed: {
    ...mapState({
      locale: (state) => state.locale,
      site: (state) => state.site,
      resizeInfo: (state) => state.resizeInfo,
    }),
    getOptions() {
      const defaultParams = this.getDefaultParams()
      const options = {
        ...defaultParams,
        show: this.isShow(),
        container: 'sfRecommend',
        lang: this.changeLang(),
        host: this.getHost(),
        visitCountry: this.getSite(),
        channelCode: this.getChannerCode(), // 当前所需渠道
        sceneCode: sceneCodeMap[this.sceneCode], // 运费时效
        componentCode: componentCodeMap[this.sceneCode], // 组件码
      }
      return options
    },
  },
  mounted() {
    const options = this.getOptions
    this.fb = new FeedBack(options)
  },
  methods: {
    // 打开
    openFeedback() {
      this.fb.open()
    },
    closeFeedback() {
      this.fb.close()
    },
    // 是否加载满意度
    isShow() {
      return window.isFeedbackOpen && !specialSite.includes(this.site)
    },
    getHost() {
      const hosts = { CN: 'CN', SG: 'SG' }
      const host = this.site.toUpperCase()
      return hosts[host] || hosts.SG
    },
    getSite() {
      return this.site ? this.site.toUpperCase() : 'CN'
    },
    // 默认参数
    getDefaultParams() {
      const env = {
        local: 'SIT',
        sit: 'SIT',
        uat: 'SIT_OUT',
        prd: 'PRD',
      }
      return {
        sysCode: 'sfintlOs',
        sysVersion: CONFIG.version,
        env: env[window.g.env] || 'PRD',
        supportClose: true, // 是否支持关闭按钮
        host: this.site === 'sg' ? 'SG' : 'CN', // 判断是否数据进入SG域
      }
    },
    //  渠道参数
    getChannerCode() {
      const { sysCode } = this.$route.query
      // 如果内嵌在外部系统
      if (sysCode && channelCodeMap[sysCode]) {
        return channelCodeMap[sysCode]
      } else {
        // 内部系统区分移动端或者PC端
        const mb = this.isMobile()
        const key = mb ? 'sfintlOsH5' : 'sfintlOsPc'
        return channelCodeMap[key]
      }
    },
    isMobile() {
      if (this.resizeInfo.isMobile === undefined) {
        return window.screen.width <= mobilePx
      }
      return this.resizeInfo.isMobile
    },
    // 国际化语言处理
    changeLang() {
      const lang = this.locale
      return typeof lang === 'string' ? lang.replace(/-/, '_') : ''
    },
  },
}
