// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/service-support/tech-top.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media screen and (min-width:1101px){.top-banner[data-v-785d93da]{height:1.06rem;line-height:1.06rem;color:#fff;font-size:0.24rem;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%;background-position:50%;margin-bottom:0.4rem;background-color:#333}.top-banner .banner-inner[data-v-785d93da]{color:#fff;width:12rem;margin:0 auto;padding-left:0.34rem}.top-banner.special[data-v-785d93da]{background:none;height:0.56rem;line-height:0.56rem;font-weight:600;margin-bottom:0.32rem;background-color:#e31d33;text-align:center}.top-banner.special .banner-inner[data-v-785d93da]{color:#fff}}@media screen and (max-width:1100px){.top-banner[data-v-785d93da]{height:1.06rem;line-height:1.06rem;color:#fff;font-size:0.36rem;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:cover;background-position:61%;margin-bottom:0;background-color:#333}.top-banner .banner-inner[data-v-785d93da]{color:#fff;width:100%;margin:0 auto;padding-left:0.34rem}.top-banner.special[data-v-785d93da]{background:none;height:0.94rem;line-height:0.94rem;font-weight:500;margin-bottom:0;background-color:#fff;text-align:center}.top-banner.special .banner-inner[data-v-785d93da]{color:#333}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
