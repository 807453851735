/*
 * @Author: yuanxing(01407381)
 * @Date: 2023-05-12 16:10:22
 * @LastEditors: yuanxing(01407381)
 * @LastEditTime: 2023-05-15 10:14:43
 * @FilePath: \ibu-owt-core-frontend\site\country\my\invoice\data.js
 * @Description:
 */
const data = {
  // 结果展示
  result: {
    mainInfo: [
      'SF GLOBAL EXPRESS (M) SDN BHD',
      'Lot 12, Jalan 51A/241, Seksyen 51a, 46100',
      'Petaling Jaya, Selangor, Malaysia',
      'Co. Reg No.: 927787-P',
      'SST Reg No.: W10-1808-31026158',
      'Hotline: 1800-18-3331 Main fax: +603-7496 0833',
      'www.sf-express.com',
    ],
    tableHeader: [
      'ITEM',
      'DESCRIPTION',
      'PRODUCT',
      'QUANTITY',
      'WEIGHT',
      'AMOUNT',
      'GST/VAT/SST',
      'TOTAL AMT(MYR)',
    ],
    hasImg: true, // 底部展示图片
  },
  // 发票申请说明
  tips: [
    'serviceSupport.invoice.tipContentMY1',
    'serviceSupport.invoice.tipContent2',
    'serviceSupport.invoice.tipContent3',
  ],
}

export default data
