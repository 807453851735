
import { mapState } from 'vuex'
import SupportNav from '@/components/page/support-nav.vue'
import SupportTop from '@/components/page/support-top.vue'
import SelfCollection from '@/modules/templates/components/self-collection/index.vue'

export default {
  components: {
    SupportNav,
    SupportTop,
    SelfCollection,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    cmsData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      resizeInfo: (state) => state.resizeInfo,
      isMap: (state) => state.isMap,
    }),
    isScOrEn() {
      return ['SC', 'EN'].includes(this.$route.params.lang.toUpperCase())
    },
    table() {
      return {
        company: this.$t(
          'serviceSupport.querySupport.service_depts.corporateName'
        ),
        selfSendPointName: this.$t(
          'serviceSupport.querySupport.service_depts.selfSendPointName'
        ),
        serviceType: this.$t(
          'serviceSupport.querySupport.service_depts.pointType'
        ),
        address: this.$t('serviceSupport.querySupport.service_depts.site'),
        postCode: this.$t('serviceSupport.querySupport.service_depts.postCode'),
        contactInfo: this.$t(
          'serviceSupport.querySupport.service_depts.telephone'
        ),
        direction: this.$t(
          'serviceSupport.querySupport.service_depts.directions'
        ),
        serviceTime: this.$t(
          'serviceSupport.querySupport.service_depts.serviceTime'
        ),
      }
    },
  },
  methods: {
    splitString(key, it) {
      let str = it[key]
      // 中英文展示英文地址, 小语种取默认地址
      if (key === 'address') {
        if (this.isScOrEn) {
          str = it.addressEn
        } else {
          str = it.address
        }
      }

      const splitList = ['serviceTime', 'selfSendPointName']
      return splitList.includes(key) ? str.replace(/\//g, '\n') : str
    },
  },
}
