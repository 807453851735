export const servicePointList = (that) => [
  {
    value: 0,
    label: that.$t('common.form.allData'),
  },
  {
    value: 1,
    label: that.$t('serviceSupport.querySupport.service_depts.dropOff'),
  },
  {
    value: 2,
    label: that.$t('serviceSupport.querySupport.service_depts.pickUp'),
  },
  {
    value: 3,
    label: that.$t('serviceSupport.querySupport.service_depts.dropAndPick'),
  },
]
