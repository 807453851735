
import station from '@/modules/site'
import LayoutMixin from '@/mixins/layout'

export default {
  mixins: [LayoutMixin],
  // 服务端 和 路由更新之前被调用
  async asyncData(content) {
    const { app, route } = content
    const {
      params: { site },
    } = route
    try {
      const res = await app.$API.home.getHomePage()
      const pageInfo = res || {}
      return {
        site,
        announcementSidebar: pageInfo.announcementSidebar || {}, // 重要公告
        bannerList: pageInfo.banner || [], // 轮播图
        announcementList: pageInfo.announcement?.slice(0, 4) || [], // 服务公告
        newsList: pageInfo.news?.slice(0, 4) || [], // 新闻动态
        popularize: pageInfo.popularize?.slice(0, 2) || [], // 推广专区
        pageInfo,
      }
    } catch (e) {
      return {
        site,
        announcementSidebar: {},
        bannerList: [],
        announcementList: [],
        newsList: [],
        popularize: [],
        pageInfo: {},
      }
    }
  },
  computed: {
    comp() {
      return station[this.site]?.index
    },
  },
}
