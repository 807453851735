import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'

const htmlToPdf = (elementId, fileName, direction = 'l') => {
  const container =
    document.querySelector(`#${elementId}`) ||
    document.querySelector(`.${elementId}`)
  html2Canvas(container).then(function (canvas) {
    const canvasWidth = canvas.width
    const canvasHeight = canvas.height
    const pageHeight = (canvasWidth / 592.28) * 841.89
    let totalHeight = canvasHeight
    let position = 0 // 偏移量
    const imgWidth = 595.28 // 生成canvas 图片的宽度
    const imgHeight = (592.28 / canvasWidth) * canvasHeight // 生成canvas 图片的高度
    const pageData = canvas.toDataURL('image/jpeg', 1.0)
    const PDF = new JsPDF('', 'pt', 'a4')
    if (totalHeight < pageHeight) {
      PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
    } else {
      // 循环生成分页
      while (totalHeight > 0) {
        PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
        totalHeight -= pageHeight
        position -= 841.89
        if (totalHeight > 0) {
          PDF.addPage() // 添加新的一页
        }
      }
    }
    PDF.save(fileName + '.pdf') // 保存pdf
  })
}

export default htmlToPdf
