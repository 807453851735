import Consign from '../sg/consign/index.vue'
import Invoice from '../sg/invoice/index.vue'
import Dept from '../sg/dept/index.vue'
import Contact from './contact/index.vue'
import Careers from './careers/index.vue'
import Product from './product/index.vue'
import Index from './index/index.vue'

export const pages = {
  index: Index, // 首页
  consign: Consign, // 服务支持 寄件流程指引
  product: Product, // 物流服务 物流产品详情
  contact: Contact, // 联系我们
  dept: Dept, // 服务网点
  invoice: Invoice, // 发票申请
  careers: Careers, // 人才招聘
}
