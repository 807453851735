
export default {
  props: {
    name: {
      type: String,
      default: 'slideInUp',
    },
  },
  data() {
    return {}
  },
  computed: {
    className() {
      return `animate__${this.name}`
    },
  },
  mounted() {
    this.$animate.init()
    this.scroll()
  },
  methods: {
    scroll() {
      const top =
        window.scrollY ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      window.scrollTo({
        top: top + 1,
        behavior: 'smooth',
      })
    },
  },
}
