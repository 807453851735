
import { mapState } from 'vuex'
import allUrls from '@/utils/config'
import gjUtmConfig from '@/utils/gjUtm'
import { TrackingHomeClick, ShippingTimeCostClick, ShippingProductClick, QueryTrackingClick } from '@/reporter/track'

export default {
  props: {
    bannerList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      searchValue: '',
      functionList: [{
        // 马上寄件
        icon: 'whole-shipping',
        name: 'navs.shipNow',
        iconName: 'home_mashangjijian',
        routeName: 'consign',
        gjUtm: gjUtmConfig.sfIntlOsSs2.jp,
        link: allUrls.iuopGloballoginUrl, // '/consign',
      },{
        // 查单
        icon: 'whole-tracking',
        name: 'navs.queryWaybill',
        iconName: 'home_yunfeishixiaochaxun',
        routeName: 'checkList',
        link: '/support/querySupport/waybill',
      },{
        // 查运费时效
        icon: 'whole-calculate',
        name: 'serviceSupport.querySupport.freight_aging',
        iconName: 'home_lijichadan',
        routeName: 'freight_aging',
        link: '/support/querySupport/freight_aging',
      }],
      trackMap: {
        consign: ShippingProductClick,
        checkList: TrackingHomeClick,
        freight_aging: ShippingTimeCostClick
      }
    }
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isHdFtMobile,
    }),
    banner() {
      return this.bannerList[0] || {}
    }
  },
  methods: {
    onTrack(routeName) {
      this.trackMap[routeName]?.()
    },
    onBannerClick(e, banner) {
      if (banner.url) {
        window.location.href = banner.url
      }
    },
    onSearch() {
      QueryTrackingClick()
      // if (this.searchValue) {
      this.$router.push({
        name: 'site-lang-support-querySupport-waybill',
        params: {
          site: this.$route.params.site,
          lang: this.$route.params.lang,
        },
        query: {
          No: this.searchValue,
        },
      })
      // }
    }
  }
}
