
import { mapState } from 'vuex'
import CusPc from './pc.vue'
import CusMb from './mb.vue'
export default {
  components: {
    CusPc,
    CusMb,
  },
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      resizeInfo: (state) => state.resizeInfo,
    }),
  },
}
