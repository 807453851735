
import { mapState } from 'vuex'
import TabItem from './TabItem'

export default {
  name: 'TabContainer1',
  components: {
    TabItem,
  },
  props: {
    curPos: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      pr1: [],
      pr2: [],
      activeTab: '',
    }
  },
  computed: {
    ...mapState({
      site: (state) => state.site,
    }),
    list1() {
      return {
        navTitle: 'modules.logisticsService.productList.express',
        children: this.pr1,
      }
    },
    list2() {
      return {
        navTitle: 'modules.logisticsService.productList.commerce',
        children: this.pr2,
      }
    },
  },
  watch: {
    curPos: {
      handler(nv) {
        this.getDataSwitch(nv)
      },
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    getDataSwitch(curPos) {
      if (curPos === 0 && (!this.pr1.length || !this.pr2)) {
        this.getLogisticsServiceMain()
      }
    },
    getLogisticsServiceMain() {
      this.$API.product.getLogisticsServiceMain({ type: 1 }).then((res) => {
        this.pr1 = res?.result?.filter((it) => it.productType === 1) || []
        this.pr2 = res?.result?.filter((it) => it.productType === 2) || []
        this.$nextTick(() => {
          this.scroll2Target()
        })
      })
    },
    scroll2Target() {
      const { subProduct } = this.$route.query
      // 有值说明从首页跳转过来，需要自动定位
      if (subProduct === 'e-commerce') {
        const targetDom = this.$el?.querySelector(`.${subProduct}`)
        document.documentElement.scrollTop = targetDom.offsetTop
      }
    },
    isActive(item, index) {
      return this.activeTab === item.id || (!this.activeTab && index === 0)
    },
  },
}
