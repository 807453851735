/**
 * 满意度接入组件
 * version V0.0.1
 * author: wenfeng
 * 1、开关控制所有功能的满意度组件是否显示
 * 2、提交成功一次5秒后自动消失，状态存在cookie中，直到用户清除cookie，否则不再出现，不提交则每次查询每次出现（用户可以点击关闭）
 * 3、提交成功后，在其他的功能、其他站点仍然会出现该组件
 * 4、渠道编码统一，速运的sysCode需要做映射，SFGO/国际App/国际小程序前端修改参数
 * 5、该版本暂时不在繁体页面显示
 */
import { getCookie, setCookie } from '@/utils/auth'

class Feedback {
  source = {
    isInit: false,
  }

  constructor(opts) {
    this.options = {
      ...opts,
      ...{
        successCallback: () => this.success.apply(this),
        failCallback: () => this.fail.apply(this),
      },
    }
    this.ck = `fb_${this.options.host}_${this.options.channelCode}_${this.options.componentCode}` // cookie key
    this.init()
  }

  // 初始化插件
  init() {
    this.isShow(() => {
      this.setScript(() => {
        this.judge(() => {
          const dom = document.getElementById(this.options.container)
          window.$iee_recommend?.init?.({ container: dom })
          this.source.isInit = true
        })
      })
    })
  }

  open() {
    this.isShow(() => {
      if (!this.source.isInit) {
        this.watch(() => this.openExecute())
      } else {
        this.openExecute()
      }
    })
  }

  openExecute() {
    this.judge(() => {
      window.$iee_recommend?.open?.(this.options)
    })
  }

  close() {
    this.judge(() => window.$iee_recommend?.close?.())
  }

  successClose() {
    const timer = setTimeout(() => {
      clearTimeout(timer)
      this.judge(() => window.$iee_recommend?.close?.())
    }, 5000)
  }

  success() {
    this.successClose()
    setCookie(this.ck, true, { expires: 365 }) // 一年
  }

  fail() {
    throw new Error(`submission fail, check network and retry`)
  }

  judge(callback) {
    if (window.$iee_recommend) {
      if (document.readyState === 'complete') {
        if (callback) {
          callback()
        }
      } else {
        document.onreadystatechange = () => {
          if (document.readyState === 'complete') {
            if (callback) {
              callback()
            }
          }
        }
      }
    } else if (document.body) {
      // throw new Error(`feedback load fail, check network and reload`)
      this.setScript(() => {
        if (callback) {
          callback()
        }
      })
    } else {
      document.addEventListener(
        'DOMContentLoaded',
        () => {
          this.setScript(() => {
            if (callback) {
              callback()
            }
          })
        },
        false
      )
    }
  }

  isShow(callback) {
    if (this.options.show) {
      const isSubmitted = getCookie(this.ck)
      if (!isSubmitted) {
        if (callback) callback()
      }
    }
  }

  watch(callback) {
    // eslint-disable-next-line accessor-pairs
    Object.defineProperty(this.source, 'isInit', {
      set(value) {
        if (value) {
          callback && callback()
        }
      },
    })
  }

  setScript(callback) {
    if (!window.$iee_recommend && !this.source.isInit) {
      try {
        const script = document.createElement('script')
        const key = 'feedbackUrl' + this.options.host
        const src = `${window.g[key]}/static/recommend-index.umd.js`
        script.setAttribute('type', 'module')
        script.setAttribute('src', src)

        script.onload = () => {
          console.log('加载完成recommend js')
          if (callback) callback()
        }
        script.onerror = () => {
          console.error('feedback load fail, check network and reload')
          // throw new Error(`feedback load fail, check network and reload`)
        }
        document.body.appendChild(script)
      } catch (e) {
        console.error(e)
        // throw new Error(e)
      }
    } else if (callback) callback()
  }
}

export default Feedback
