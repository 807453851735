
import NavTitle from '../-NavTitle'
import OssImg from '@/components/OssImg.vue'

export default {
  name: 'TabContainer1',
  components: {
    NavTitle,
    OssImg,
  },
  props: {
    list: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {}
  },
  methods: {
    isLegalWebsite(url) {
      const reg = /(http(s)?:\/\/)?[a-z0-9A-Z]+\.(com|cn|net)(.+)?/
      if (reg.test(url)) {
        return url
      } else {
        return '#'
      }
    },
    // 跳转到指定项
    handleScrollTo(item) {
      const id = `a_${item.id}`
      const destDom = document.getElementById(id)
      destDom.scrollIntoView({ block: 'center', behavior: 'smooth' })
    },
  },
}
