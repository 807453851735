/**
 * 注意：1、要在查询是去加载滑块验证SDK，验证tk
 *      2、接口未返回时不能二次发起验证请求
 * 修改：2014/1/5
 *      1、删除腾讯滑块
 *      2、添加极验滑块
 */
import { message } from 'ant-design-vue'
import { mobilePx } from '@/utils/constBase'

const getLang = (locale) => {
  const langs = ['zh_CN', 'zh_HK', 'en_US', 'th_TH', 'vi_VN', 'ja_JP']
  const lang = locale ? locale.replace(/-/, '_') : 'en_US'
  return langs.find((it) => it === lang) || 'zh_HK'
}

/**
 * @description:获取当前环境
 * prd-sfyun  顺丰云生产
 * sit 顺丰云外网
 * sit-lan 顺丰云内网
 * gcp 谷歌云测试环境
 * prd-gcp 谷歌云生产
 * @return {*}
 */
const getEnv = (env) => {
  const map = {
    prd: 'prd-sfyun',
    uat: 'sit',
    sit: 'sit',
    local: 'sit',
  }
  return map[env] || 'prd-sfyun'
}

class Captcha {
  SYS_CODE = 'IBU-OWT-CORE'
  SCENE = '2' // 场景：1--移动端（腾讯-谷歌[静默]-顺丰） 2--登陆、注册（腾讯-谷歌-顺丰），3--小程序（顺丰-谷歌[静默]-腾讯）
  captcha
  options
  loading = false

  init(opts) {
    this.assign(opts)
  }

  assign(opts) {
    const options = {
      client: 'web-pc',
      sysCode: this.SYS_CODE,
      scene: this.SCENE,
      useLoading: true,
      env: getEnv(window.g.env),
      app: null,
    }
    if (this.isMobile()) {
      options.scene = '1'
    }
    if (opts.scene === '3') {
      options.client = 'mini-app'
    }
    this.options = { ...options, ...opts }
  }

  verify() {
    return this.loadScript().then((result) => {
      if (result) {
        this.captcha = new window.ICaptcha(this.options)
        this.captcha.setLang(getLang(this.options.lang))
        return this.captcha
          .verify()
          .then((res) => {
            const params = {}
            params.ticket = res.token
            params.captchaType = res.type
            params.tk = res.tk
            return Promise.resolve(params)
          })
          .catch((error) => {
            this.errorMessage(error.errorCode)
            return Promise.reject(error)
          })
      }
    })
  }

  loadScript() {
    return new Promise((resolve) => {
      if (this.loading) return false
      if (!window.ICaptcha) {
        try {
          this.loading = true
          const script = document.createElement('script')
          // const key = 'captchaUrl'
          const src = '/captcha.js'
          script.setAttribute('src', src)

          script.onload = () => {
            this.loading = false
            console.log('加载完成captcha js')
            resolve(true)
          }
          script.onerror = () => {
            resolve(false)
            this.reportError()
            this.loading = false
            throw new Error(`captcha load fail, check network and reload`)
          }
          document.body.appendChild(script)
        } catch (e) {
          resolve(false)
          this.reportError()
          this.loading = false
          console.error(e)
          // throw new Error(e)
        }
      } else {
        resolve(true)
      }
    })
  }

  isMobile() {
    return window.screen.width <= mobilePx
  }

  errorMessage(code) {
    const map = {
      10001: this.options.app.$t('captcha.error.error1'),
      10002: this.options.app.$t('captcha.error.error2'),
      10003: this.options.app.$t('captcha.error.error3'),
      10004: this.options.app.$t('captcha.error.error4'),
      10005: this.options.app.$t('captcha.error.error5'),
    }
    message.error(map[code])
  }

  // 调用上报异常的方法
  reportError() {
    const reportScriptLoadError =
      require('@ibu/icaptcha-report-util').reportScriptLoadError
    const { app, ...other } = this.options
    reportScriptLoadError(other)
  }
}

export default new Captcha()
