
import { mapState } from 'vuex'
import { formatTimeWithMoment } from '@/utils/date'

export default {

  props: {
    title: {
      type: String,
      default: ''
    },
    path: {
      type: String,
      default: ''
    },
    track: {
      type: String,
      default: ''
    },
    panelList: {
      type: Array,
      default: () => []
    },
    background: {
      type: Boolean,
      default: false
    },
    detailPath: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({}),
  },
  methods: {
    formatTimeWithMoment
  }
}
