
export default {
  name: 'ModuleHeader',
  props: {
    title: {
      type: String,
      required: false,
      default: () => '',
    },
    styleObject: {
      type: Object,
      required: false,
      default: () => {},
    },
    classObject: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
}
