
import SupportTop from '@/components/page/support-top.vue'

export default {
  components: {
    SupportTop,
  },
  props: {
    result: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    serviceList() {
      return this.result || []
    },
    site() {
      return this.$route.params.site
    },
    lang() {
      return this.$route.params.lang
    }
  },
  methods: {
    goCareerDetail(id) {
      this.$router.push({
        name: 'site-lang-about-careers-detail',
        params: {
          site: this.site,
          lang: this.lang
        },
        query: {
          id,
        }
      })
    }
  }
}
