import Index from './index/index.vue'
import Consign from './consign/index.vue'
import Product from './product/index.vue'
import Contact from './contact/index.vue'
import Dept from './dept/index.vue'

export const pages = {
  index: Index,
  consign: Consign,
  product: Product,
  contact: Contact,
  dept: Dept,
}
