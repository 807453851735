
import data from './data'
import Index from '@/modules/templates/default/index/index.vue'

export default {
  components: {
    Index,
  },
  props: {
    bannerList: {
      type: Array,
      default: () => [],
    },
    announcementList: {
      type: Array,
      default: () => [],
    },
    newsList: {
      type: Array,
      default: () => [],
    },
    announcementSidebar: {
      type: Object,
      default: () => ({}),
    },
    pageInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      data,
    }
  },
}
