
import { mapState } from 'vuex'
import Product from './product.vue'
import ProductMb from './product-mb.vue'
import { formatTimeWithMoment } from '@/utils/date'
import OssImg from '@/components/OssImg.vue'

export default {
  components: {
    OssImg,
    Product,
    ProductMb,
  },
  props: {
    clientData: {
      type: Object,
      default: () => ({}),
    },
    bannerList: {
      type: Array,
      default: () => [],
    },
    announcementList: {
      type: Array,
      default: () => [],
    },
    newsList: {
      type: Array,
      default: () => [],
    },
    announcementSidebar: {
      type: Object,
      default: () => ({}),
    },
    pageInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      news_wrap_imgUrl: '~/assets/img/home/solution_bg2.png',
      product_wrap_imgUrl: '~/assets/img/home/solution_bg1.jpg',
      popularize: [],
      isGrey: false,
    }
  },
  head() {
    return {
      title: this.$t(`modules.home.pageTitle`) + this.$t('navs.suffixName'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('descriptions.home'),
        },
      ],
    }
  },
  computed: {
    ...mapState({
      site: (state) => state.site,
      counter: (state) => state.counter,
      resizeInfo: (state) => state.resizeInfo,
      isHdFtMobile: (state) => state.isHdFtMobile,
      isAgree: (state) => state.cookie_agree.agree,
      cookieBtnDisagree: (state) => state.cookie_btn_disagree,
    }),
    isLongSite() {
      return ['au'].includes(this.site)
    },
  },
  mounted() {
    this.isGrey = window.isGrey
    this.registryWatch()
  },
  methods: {
    handler() {
      // console.log('图片出现')
    },
    registryWatch() {
      this.$watch(
        function () {
          return this.resizeInfo.isMobile
            ? this.pageInfo?.popularize?.slice(0, 1)
            : this.isLongSite
            ? this.pageInfo?.popularize?.slice(0, 3)
            : this.pageInfo?.popularize?.slice(0, 2)
        },
        function (val) {
          this.popularize = val
        },
        { immediate: true }
      )
    },
    formatTimeWithMoment,
    jumpDetail(item) {
      const { path } = this.$route
      let temp = `${path}/about/detail`
      temp = temp.replace(/\/\//g, '/')
      this.$router.push({
        path: temp,
        query: {
          code: item.code,
        },
      })
    },
    // 控制 马上寄件、运费时效查询、立即查单一屏展示
    // setFullPage() {
    //   if (!this.isHdFtMobile) {
    //     // 只有pc端需要适配
    //     const clientHeight = document.body.clientHeight
    //     const headerHeight = document.getElementById('header').offsetHeight
    //     const carouselDom = document.querySelector('.carousel_wrap')
    //     const searchHeight = document.querySelector('.search_wrap').offsetHeight
    //     const carouselHeight = clientHeight - headerHeight - searchHeight
    //     carouselDom.style.height = carouselHeight + 'px'
    //   }
    // },
    handleLoggerTrack(key) {
      // this.$logger.customTrack({ type: 'click', event: key })
    },
  },
}
