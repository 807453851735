
import { decodeBase64 } from '@/utils/utils'
export default {
  name: 'Base64Img',
  props: {
    imageType: {
      type: String,
      default: '',
    },
    imageBase64: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
  computed: {
    isTif() {
      return this.imageType === 'tif'
    },
  },
  mounted() {
    if (this.isTif) {
      this.renderTif(this.imageBase64)
    }
  },
  watch: {
    imageBase64: {
      handler(nv) {
        this.renderTif(nv)
      },
    },
  },
  methods: {
    renderTif(imageBase64) {
      const buffer = decodeBase64(imageBase64)
      // eslint-disable-next-line
      const tiff = new window.Tiff({ buffer })
      if (tiff._tiffPtr !== 0) {
        const canvas = tiff.toCanvas()
        canvas.setAttribute('style', 'max-width: 100%;')
        const canvasItem = this.$el.querySelector('.tif-img')
        canvasItem?.appendChild(canvas)
      }
    },
  },
}
