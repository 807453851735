
import HeadBanner from '~/components/common/HeadBanner'
export default {
  components: {
    HeadBanner,
  },
  data() {
    return {
      toSunday: this.$t('relation.OfficeTime'),
      officeTime8_21: this.$t('relation.OfficeTime8_21'), //
      officeTime9_18: this.$t('relation.OfficeTime9_18'),
      officeTime9_20: this.$t('relation.OfficeTime9_20'),
    }
  },
  head() {
    return {
      title: this.$t(`modules.about.relations`) + this.$t('navs.suffixName'),
    }
  },
  mounted() {
    // this.$logger.customTrack({
    //   type: 'visit',
    //   event: 'contact_us_page|联系我们页面',
    // })
  },
  methods: {
    // sfbuy客服
    sfBuyService() {
      let url = window.location.href
      url = encodeURIComponent(url)
      const url1 =
        'http://ocs2odp.sf-express.com/app/init?orgName=sy&channelId=140&clientType=1&accountId='
      const url2 = '&pagereferrer='
      window.open(
        url1 + url + url2 + url,
        'newwindow',
        'height=600,width=800,top=0,left=0,toolbar=no,menubar=no,location=no,resizable=yes,status=yes'
      )
    },
  },
}
