
import { mapState } from 'vuex'
import htmlToPdf from '@/utils/htmlToPdf.js'
import { downloadBase64Short } from '@/utils/utils'
export default {
  props: {
    result: {
      type: Object,
      default: () => ({}),
    },
    clientData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      feeItemsEn: {
        1: 'Freight Charges', // 运费
        2: 'Tariff', // 关税
        // 3: 'Value Added Tax', // 增值税
        3: 'Shipment Protection Plus (SPP)', // 基础保
        4: 'Customs duties', // 海关税
        5: 'Trade Declaration Service', // 报关服务费
        // 6: 'Godown Charge', // 报关仓储费
        6: 'Import Declaration', // 进口报关费
        14: 'Fuel Surcharge', // 燃油附加费
        // 48: 'Extra Long Weight Charges', // 超长超重服务
        48: 'Overweight and Oversize', // 超长超重服务
        67: 'Packaging Charges', // 包装服务费
        // 92: 'Temporary Surcharge', // 高峰资源调节费
        92: 'Resource Allocation Fee', // 资源调节费
        // 96: 'EXD Handling Fee', // 国际电商专递操作费
        96: 'E-Commerce Express Handling Fee', // 国际电商专递操作费
        137: 'Export Declaration', // 出口报关费
        23: 'Shipment Protection Plus (High-value)', // 基础保（高价值）
        41: 'Fixed Time Delivery (Appointed Time)', // 定时派送（指定时段）
      },
      productCode: {
        SE0001: '顺丰标快', // 'normal-product'
        SE0002: '顺丰即日', // 'same-day'
        SE000203: '顺丰当日配', // 'same-day'
        SE0003: '顺丰次晨', // 'other-morning'
        SE0004: '顺丰特惠', // 'special-product'
        SE0005: 'Economy Express', // '国际特惠'
        SE0006: '物流普运', // 'internation-cheap'
        SE0008: '便利装标快', // 'convenience'
        SE0101: '小票零担', // 'small-lent'
        SE0114: '大票零担', // 'big-lent'
        SE001001: '重货包裹',
        SE0020: '重货专运', // 'heavy-spacial'
        SE0021: '重货快运', // 'Heavy-Speed'
        SE0051: 'Standard Express', // '国际标快'
        SE0032: '国际小包', // 'internation-small'
        SE0033: '国际小包挂号',
        SE0037: 'Economy Express (Bonded)', // '国际电商保税',
        SE0029: 'Economy Express(Pilot)', // '国际电商专递-BC'
        SE0054: 'E-Commerce Express(EX)', // '国际电商专递'
        SE005401: '国际电商专递-快速',
        SE005402: '国际电商专递-标准',
        SE005403: '国际电商专递-送仓',
        SE0079: "Int'l Heavy Freight", // '国际重货'
        SE007901: '国际重货-门到门 ',
        SE007902: '国际重货-FBA ',
        SE007903: '国际重货-港到港 ',
        SE007904: '国际重货-海快船+派',
        SE007905: '国际重货-海普船+派',
        SE007906: '国际重货-门到港',
        SE007907: '国际重货-陆运',
        SE007908: '国际重货-海外仓空+派',
        SE007909: '国际重货-海外仓海+派',
        SE007910: '国际重货-海外仓铁+派',
        SE0011: 'Trans shipment', // '集货转运'
        SE0111: 'Overseas Warehouse', // '海外仓储'
        SE0113: '冷运特惠', // 'cold-cheap'
        SE0112: '冷运速配', // 'cold-cheap'
        SE0059: '冷运到店', // 'cold-arrive'
        SE0030: '冷运零担', // 'cold-heavy'
        SE003001: '冷运小票零担',
        SE0031: '冷运专车', // 'cold-car'
        SE0038: '冷运仓储', // 'cold-storage'
        SE0024: '医药专递', // 'medical-special'
        SE0125: '精温定达', // 'medical-dingda'
        SE0126: '精温定航', // 'medical-dinghang'
        SE0076: '医药专车', // 'medical-car'
        SE0077: '医药仓储', // 'medical-storage'
        SE0122: '特惠专配', // 'cheap-special'
        SE0117: '特惠专配C', // 'cheap-C'
        SE0118: '特惠专配B', // 'cheap-B'
        SE000404: '特惠D类',
        SE0089: '顺丰空配', // 'Air-package'
        SE0096: '高铁急速配', // 'High-speed-trains'
        SE0097: '高铁极速达次晨',
        SE0027: '生鲜速配', // 'Fresh-Speed'
        SE0034: '大闸蟹专递', // 'Crabs-Special'
        SE0088: '顺丰干配',
        SE0115: '陆运微小件',
        SE0107: '极速包裹',
        SE0109: '顺丰微小件',
        SE0102: '高铁顺手寄标准',
        SE0103: '高铁专送',
        SE0104: '高铁顺手寄特惠',
        SE000204: '顺丰城际急送',
        SE0064: 'E顺递',
        SE0068: '物资配送',
        SE0023: '医药安心递',
        SE0110: '医药快运',
        SE0075: '医药零担',
        SE0091: '专线普运',
        SE0121: '限时KC24',
        SE0043: 'SFBuy Self-pickup service', // '集货转运店配'
        SE0044: 'Economy Express (Agent)', // '国际特惠（商家代理）'
        SE0095: "Int'l Cold Chain", // 国际冷运'
        SE0106: '国际填舱',
        SE0123: 'Overseas Domestic Express', // '海外同城件'
        SE0120: "Int'l Ocean Shipping", // '国际海运'
      },
    }
  },
  computed: {
    ...mapState({
      mobile: (state) => state.isHdFtMobile,
    }),
    resultObj() {
      return this.result?.fcInvoiceItem || {}
    },
    resultConfig() {
      return this.clientData.result
    },
    hasFeeItems() {
      return this.resultObj?.feeItems?.length > 0
    },
  },
  mounted() {},
  methods: {
    download() {
      htmlToPdf(
        'invoice-pdf',
        `${this.$t('serviceSupport.invoice.invoiceRequest')}_${
          this.resultObj?.waybillNo || ''
        }`
      )
    },
    handleDownloadImg(item, fileName) {
      if (item.imageBase64) {
        downloadBase64Short(fileName, item)
      }
    },
  },
}
