import { render, staticRenderFns } from "./TabContainer1Mb.vue?vue&type=template&id=75f420e4&scoped=true&"
import script from "./TabContainer1Mb.vue?vue&type=script&lang=js&"
export * from "./TabContainer1Mb.vue?vue&type=script&lang=js&"
import style0 from "./TabContainer1Mb.vue?vue&type=style&index=0&id=75f420e4&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75f420e4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BackTop: require('/app/workspace/DEPLOY/ibu-owt-core/ibu-owt-core-owt-ssr-nuxt/components/BackTop.vue').default})
