
import { mapState } from 'vuex'
export default {
  name: '',
  prop: {
    modelValue: {
      type: String,
      default: '',
    },
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
    }),
    innerActiveTab: {
      get() {
        return this.modelValue
      },
      set(v) {
        return this.$emit('update:modelValue', v)
      },
    },
  },
  methods: {},
}
