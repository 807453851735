
export default {
  name: '',

  data() {
    return {}
  },

  methods: {},
}
