
import { mapState } from 'vuex'
import captcha from '@/utils/captcha.js'
export default {
  name: '',
  props: {
    // 业务场景 1: 通关证明文件查询 2：发票申请
    businessType: {
      type: String,
      default: '',
    },
    app: {
      type: String,
      default: '', // 顺丰滑块需要
    },
    waybillPlaceholder: {
      type: String,
      default: '',
    },
    hasLandline: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const checkLength = (rule, value, callback) => {
      if (value.length !== 12 && value.length !== 15) {
        return callback(
          new Error(this.$t('serviceSupport.clearanceService.inputCNo'))
        )
      } else {
        callback()
      }
    }
    const maxCount = 60
    return {
      mobileAreaCodeList: [], // 手机区号列表
      form: {
        businessType: this.businessType, // 业务场景
        waybillNo: '',
        mobile: '',
        mobileCode: '',
        sendType: 'mobile', // 默认手机校验
        email: '',
        emailCode: '',
        app: this.app, // 顺丰滑块需要
        countryCode: 'CN', // 手机默认国家,手机区号可能相同，故取国家二字码
      },
      mobileTimer: null,
      emailTimer: null,
      maxCount,
      mobileCount: maxCount, // 60s
      emailCount: maxCount, // 邮箱 60s
      isBtnDisabled: true, // 查询按钮默认置灰
      isMobileBtnLoading: false,
      isEmailBtnLoading: false,
      isLoading: false,
      rules: {
        waybillNo: [
          {
            required: true,
            message: this.$t('serviceSupport.clearanceService.inputNo'),
            trigger: 'blur',
          },
          {
            validator: checkLength,
            trigger: 'change',
          },
        ],
        mobile: [
          {
            required: true,
            message: this.$t(
              'serviceSupport.clearanceService.certificateQuery.mobilePlaceholder'
            ),
            trigger: 'blur',
          },
          {
            asyncValidator: (rule, value) => {
              return new Promise((resolve, reject) => {
                this.checkPhone((res) => {
                  if (!res) {
                    reject(
                      this.$t(
                        'serviceSupport.clearanceService.certificateQuery.mobileTip'
                      )
                    ) // reject with error message
                  } else {
                    resolve()
                  }
                })
              })
            },
            trigger: 'blur',
          },
        ],
        mobileCode: [
          {
            required: true,
            message: this.$t(
              'serviceSupport.clearanceService.certificateQuery.mobileCodePlaceholder'
            ),
            trigger: 'blur',
          },
          {
            pattern: /^\d{6}$/,
            message: this.$t(
              'serviceSupport.clearanceService.certificateQuery.verificationCodeTip'
            ),
            trigger: 'blur',
          },
        ],
        email: [
          {
            required: true,
            message: this.$t(
              'serviceSupport.clearanceService.certificateQuery.emailPlaceholder'
            ),
            trigger: 'blur',
          },
          {
            pattern:
              // eslint-disable-next-line
              /^\w+((-\w*)|(\.\w+)|(\+\w*))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/,
            message: this.$t(
              'serviceSupport.clearanceService.certificateQuery.emailTip'
            ),
            trigger: ['blur'],
          },
        ],
        emailCode: [
          {
            required: true,
            message: this.$t(
              'serviceSupport.clearanceService.certificateQuery.emailCodePlaceholder'
            ),
            trigger: 'blur',
          },
          {
            pattern: /^\d{6}$/,
            message: this.$t(
              'serviceSupport.clearanceService.certificateQuery.verificationCodeTip'
            ),
            trigger: 'blur',
          },
        ],
        landline: [
          {
            required: true,
            message: this.$t(
              'serviceSupport.clearanceService.certificateQuery.landlinePlaceholder'
            ),
            trigger: 'blur',
          },
          {
            pattern: /^\d{4}$/,
            message: this.$t(
              'serviceSupport.clearanceService.certificateQuery.landlinePlaceholder'
            ),
            trigger: 'blur',
          },
        ],
      },
    }
  },
  computed: {
    ...mapState({
      locale: (state) => state.locale,
      site: (state) => state.site,
    }),
    // 获取当前手机验证码读秒时间
    getMobileSecondsText() {
      return this.mobileCount === this.maxCount
        ? this.$t('common.verification.getCode')
        : this.$t('common.verification.remainReFetch', [this.mobileCount])
    },
    getEmailSecondsText() {
      return this.emailCount === this.maxCount
        ? this.$t('common.verification.getCode')
        : this.$t('common.verification.remainReFetch', [this.emailCount])
    },

    // 获取当前手机区号
    curPhoneCode() {
      const matchedItem = this.mobileAreaCodeList.find(
        (item) => item.countryCode === this.form.countryCode
      )
      return matchedItem?.phoneCode || ''
    },
    isMobileVerify() {
      return this.form.sendType === 'mobile'
    },
    isEmailVerify() {
      return this.form.sendType === 'email'
    },
    isLandlineVerify() {
      return this.form.sendType === 'landline'
    },
    // 验证码模式
    isVerifyCodeType() {
      return this.isMobileVerify || this.isEmailVerify
    },
    isLandLineBtnState() {
      return (
        this.isLandlineVerify &&
        this.form.waybillNo?.length > 0 &&
        this.form.landline?.length > 0
      )
    },
  },
  created() {
    if (process.client) {
      captcha.init({ lang: this.locale, app: this })
    }
    this.fetchMobileAreaCodeList()
  },
  methods: {
    // 获取手机区号列表
    async fetchMobileAreaCodeList() {
      try {
        const res = await this.$API.support.getCustomsDeclareCountryListApi()
        this.mobileAreaCodeList = res.map((item) => {
          return {
            countryCode: item.countryCode,
            countryName: item.countryName,
            countryNameI18n: item.countryNameI18n,
            phoneCode: item.phoneCode,
            phoneDisplayCode: `+${item.phoneCode}`,
          }
        })
        // 默认展示当前站点手机区号
        const matchedItem = this.mobileAreaCodeList?.find(
          (i) => i.countryCode === this.site.toUpperCase()
        )
        this.form.countryCode = matchedItem?.countryCode || 'CN'
      } catch (e) {
        console.log(e)
      }
    },
    // 获取数据
    getData(params) {
      // 发送验证码请求
      if (this.isMobileVerify) {
        this.sendSms(params)
      } else if (this.isEmailVerify) {
        this.sendEmail(params)
      } else {
        const newParams = {
          waybillNo: this.form.waybillNo, // 运单号
          mobileNoLast4: this.form.landline, // 座机尾号
          sendType: this.form.sendType,
        }
        this.$emit('submit', { ...params, ...newParams })
      }
    },
    // 查询
    onSubmit() {
      this.$refs.queryFormRef.validate((isValid) => {
        if (isValid) {
          this.isLoading = true
          if (this.isVerifyCodeType) {
            const params = {
              businessType: this.form.businessType,
              email: this.form.email, // 国家二字码
              phoneCode: this.curPhoneCode, // 区号
              phoneNumber: this.form.mobile, // 手机号
              waybillNo: this.form.waybillNo, // 运单号
              sendType: this.form.sendType, // 发送场景  1: 短信  2: 邮箱
              validateCode: this.form[`${this.form.sendType}Code`], // 验证码
            }
            this.$emit('submit', params)
          } else {
            // 唤起滑块
            captcha.verify().then((res) => {
              this.getData(res)
            })
          }
        } else {
          return false
        }
      })
    },
    handleSubmitCallback() {
      this.isLoading = false
      this.form.mobileCode = ''
      this.form.emailCode = ''
    },
    handleTabChange(val) {
      const preValue = this.$el.querySelector(
        '.ant-tabs-tabpane.ant-tabs-tabpane-active'
      ).classList[0]
      // 先拦截，清除当前校验结果
      this.form.sendType = preValue
      this.$refs.queryFormRef.clearValidate([preValue, `${preValue}Code`])
      this.form = Object.assign(this.form, {
        [preValue]: '',
        [`${preValue}Code`]: '',
      })
      // 再放行
      this.form.sendType = val
      this.isBtnDisabled = true
      // 固话直接可点击
      if (val === 'landline') {
        // this.isBtnDisabled = false
      }
      this.$emit('tabChange')
    },
    handleFetchCode() {
      const sendType = this.form.sendType
      let count = 0
      this.$refs.queryFormRef.validateField(
        ['waybillNo', sendType],
        (errMessage) => {
          !errMessage && count++
          // 说明全部校验通过
          if (count === 2) {
            // 唤起滑块
            captcha.verify().then((res) => {
              this.getData(res)
            })
          }
        }
      )
    },
    // 手机号校验
    async checkPhone(callback) {
      const params = {
        countryCode: this.form.countryCode, // 国家二字码
        phoneCode: this.curPhoneCode, // 区号
        phoneNumber: this.form.mobile, // 手机号
      }
      try {
        const res = await this.$API.support.checkPhoneApi(params)
        callback(res)
      } catch (e) {
        console.log(e)
      }
    },
    // 手机号发送
    async sendSms(options) {
      const params = {
        businessType: this.form.businessType, // 业务场景
        country: this.form.countryCode, // 国家二字码
        phoneCode: this.curPhoneCode, // 区号
        phoneNumber: this.form.mobile, // 手机号
        sfWaybillNo: this.form.waybillNo, // 运单号
        app: this.form.app, // 标记(顺丰滑块验证)
        ...options,
      }
      try {
        this.isMobileBtnLoading = true
        const res = await this.$API.support.sendSmsApi(params)
        this.isMobileBtnLoading = false
        if (res) {
          this.isBtnDisabled = false
          this.readSeconds()
          this.$message.success(
            this.$t(
              'serviceSupport.clearanceService.certificateQuery.verificationSuccess'
            )
          )
        }
      } catch (e) {
        this.isMobileBtnLoading = false
        console.error(e)
      }
    },
    // 邮箱校验
    async sendEmail(options) {
      const params = {
        businessType: this.form.businessType, // 业务场景
        email: this.form.email, // 邮箱
        sfWaybillNo: this.form.waybillNo, // 运单号
        app: this.form.app, // 标记(顺丰滑块验证)
        ...options,
      }
      try {
        this.isEmailBtnLoading = true
        const res = await this.$API.support.sendEmailApi(params)
        this.isEmailBtnLoading = false
        if (res) {
          this.readSeconds()
          this.isBtnDisabled = false
          this.$message.success(
            this.$t(
              'serviceSupport.clearanceService.certificateQuery.verificationSuccess'
            )
          )
        }
      } catch (e) {
        this.isEmailBtnLoading = false
        console.error(e)
      }
    },
    // 读秒
    readSeconds() {
      const sendType = this.form.sendType
      // 60s 倒计时
      this[`${sendType}Count`]--
      this[`${sendType}Timer`] = window.setInterval(() => {
        this[`${sendType}Count`]--
        if (this[`${sendType}Count`] === 0) {
          window.clearInterval(this[`${sendType}Timer`])
          this[`${sendType}Count`] = this.maxCount
        }
      }, 1000)
    },
    // 过滤
    filterOption(input, option) {
      return option.data.key?.toLowerCase()?.includes(input.toLowerCase())
    },
    handleBlur(e) {
      this.form.waybillNo = e?.target?.value.toUpperCase()
    },
  },
}
