
import { mapState } from 'vuex'
import SupportNav from '@/components/page/support-nav.vue'
import SupportTop from '@/components/page/support-top.vue'
import CONFIG from '@/utils/config'
import { TABTYPE } from '@/utils/constBase'
import { QueryServicepointClick } from '@/reporter/track'
// 服务内容
const serviceContentEnum = {
  1: '自寄服务',
  2: '自取服务',
  3: '寄、取件服务',
  4: '个人地址服务',
  5: '便民服务',
  6: '自寄自取优惠服务',
}

export default {
  components: {
    SupportNav,
    SupportTop,
  },
  data() {
    return {
      showResult: false,
      pleaseSelect: 'main.pleaseSelect',
      searchbtn: 'button.search',
      amapInstance: null,
      // 地理编码
      amapGeocoder: null,
      splitSym: '-',
      formLayout: 'vertical',
      form: this.$form.createForm(this, { name: 'dept' }),
      // 选择区域限制层级
      areaSingle: true,
      showTabRule: {
        [TABTYPE.inland]: true,
      },
      trackMap: {
        query: QueryServicepointClick,
      },
    }
  },
  computed: {
    ...mapState({
      resizeInfo: (state) => state.resizeInfo,
      locale: (state) => state.locale,
    }),
  },
  mounted() {
    const AMapLoader = require('@amap/amap-jsapi-loader')
    // this.$logger.customTrack({
    //   type: 'visit',
    //   event: 'service_point_page|查服务网点页面',
    // })
    AMapLoader.load({
      key: CONFIG.amapKey, // 申请好的Web端开发者Key，首次调用 load 时必填
      version: '1.4.15', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      plugins: [
        'AMap.Scale',
        'AMap.ToolBar',
        'AMap.Geocoder',
        'AMap.Marker',
        'AMap.AdvancedInfoWindow',
      ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      AMapUI: {
        // 是否加载 AMapUI，缺省不加载
        version: '1.1', // AMapUI 缺省 1.1
        plugins: ['overlay/SimpleMarker'], // 需要加载的 AMapUI ui插件
      },
      Loca: {
        // 是否加载 Loca， 缺省不加载
        version: '1.3.2', // Loca 版本，缺省 1.3.2
      },
    })
      .then((AMap) => {
        this.initMap(AMap)
      })
      .catch((e) => {
        // console.log(e)
      })
  },
  beforeDestroy() {
    this.map.destroy()
  },
  methods: {
    // 初始化地图
    initMap(AMap) {
      this.amapInstance = AMap
      const map = new AMap.Map('amap_container', {
        resizeEnable: true,
        zoom: 10,
        center: [112.968473, 28.199468],
      })
      this.map = map
      const infoWindow = new AMap.InfoWindow()
      const marker = new AMap.Marker({
        position: [112.968473, 28.199468],
      })
      marker.setMap(map)
      marker.on('click', function (e) {
        infoWindow.open(map, e.target.getPosition())
      })
      this.amapGeocoder = new AMap.Geocoder({
        // city: "香港特别行政区", //城市，默认：“全国”
        radius: 1000, // 范围，默认：1000
      })
    },
    // 拼接地址 03
    joinAddress(area, detailAddress) {
      let address = ''
      const areaList = area.split(this.splitSym)
      for (let i = 0; i < areaList.length; i++) {
        address += areaList[i]
      }
      address += detailAddress || ''
      return address
    },
    // 获取地理编码 promise 04
    getLocationPromise(address) {
      // console.log('amapGeocoder address', address)
      return new Promise((resolve, reject) => {
        this.amapGeocoder.getLocation(address, (status, result) => {
          // console.log('amapGeocoder getLocation status, result', status, result)
          if (status === 'complete' && result.info === 'OK') {
            resolve(result)
          } else {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject()
          }
        })
      })
    },
    // 获取查询服务网点数据 05
    async queryDept(params) {
      return await this.$API.support.getQueryServiceApi({
        distance: 3000,
        ...params,
      })
    },
    // 获取服务网点数据，对数据进行操作 06
    initData(data, { lng, lat }) {
      const AMap = this.amapInstance
      const map = new AMap.Map('amap_container', {
        resizeEnable: true,
        zoom: 12,
        center: [lng, lat],
      })
      const infoWindow = new AMap.InfoWindow()

      for (let i = 0, marker; i < data.length; i++) {
        marker = new AMap.Marker({
          position: [data[i].longitude, data[i].latitude],
          extData: data[i],
          title: data[i].name,
          map,
        })
        // 给Marker绑定单击事件
        marker.on('click', function (e) {
          const data = e.target.getExtData()
          const info = []
          info.push('<div>') // 使用Map的自带样式
          info.push(
            '<p class="icon_wrap"><i class="sf-self-icon sf-icon-shops"></i><span>店名：' +
              data.name +
              '</span></p>'
          )
          info.push(
            '<p class="icon_wrap"><i class="sf-self-icon sf-icon-place"></i><span>地址：' +
              data.address +
              '</span></p>'
          )
          info.push(
            '<p class="icon_wrap"><i class="sf-self-icon sf-icon-time"></i><span>服务时间段：' +
              data.servtime +
              '</span></p>'
          )
          info.push(
            '<p class="icon_wrap"><i class="sf-self-icon sf-icon-phone"></i><span>联系电话：' +
              data.telephone +
              '</span></p>'
          )
          if (data.servertype) {
            const serviceList = data.servertype.split(',')
            let serviceContentStr = ''
            serviceList.map((item, index) => {
              if (serviceContentEnum[item]) {
                serviceContentStr += serviceContentEnum[item]
              } else {
                serviceContentStr += '其他'
              }
              if (index + 1 < serviceList.length) {
                serviceContentStr += ','
              }
              return item
            })

            info.push(
              '<p class="icon_wrap"><i class="sf-self-icon sf-icon-wangdian"></i><span>服务内容：' +
                serviceContentStr +
                '</span></p>'
            )
          }
          info.push('</div>')
          infoWindow.setContent(info.join(''))
          infoWindow.open(map, e.target.getPosition())
        })
      }
      map.setFitView()
    },
    // 获取地址
    adaptParams(params = {}) {
      try {
        const {
          area: { countryName = '中国内地', province, city },
        } = params
        let county = params.area.county
        const newCountyList = ['坪山区', '龙华区', '光明区']
        if (newCountyList.includes(county)) {
          county = county.replace(/区/, '新区')
        }
        const str = [countryName, province, city, county]
          .filter((item) => item)
          .join(this.splitSym)
        return str
      } catch (e) {}
    },
    // 检验通过之后，操作 02
    async submit(values) {
      const area = this.adaptParams(values)
      // 获取地址
      const address = this.joinAddress(area, values.address)
      // 获取地址编码
      const locationResult = await this.getLocationPromise(address)
      if (locationResult) {
        // 记录点击查询事件
        // this.$logger.customTrack(
        //   {
        //     type: 'click',
        //     event: 'service_coverage_query_click|查服务网点查询按钮点击率',
        //   },
        //   { formData: values }
        // )
        const location = locationResult.geocodes[0].location
        const resData = await this.queryDept({
          lng: location.lng,
          lat: location.lat,
        })
        if (resData) {
          this.initData(resData, {
            lng: location.lng,
            lat: location.lat,
          })
        }
      }
    },
    // submit 01
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.showResult = true
          this.trackFn()
          this.submit(values)
        }
      })
    },
    trackFn(properties) {
      this.trackMap.query(properties)
    },
  },
}
