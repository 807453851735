
export default {
  model: {
    prop: 'modelValue',
    event: 'updateValue'
  },
  props: {
    radioList: {
      type: Array,
      default: () => []
    },
    modelValue: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {}
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(v) {
        this.$emit('updateValue', v)
      } 
    }
  },
  methods: {
    onChange(value) {
      this.value = value
      this.$emit('change', value)
    }
  }
}
