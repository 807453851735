const data = {
  result: {
    mainInfo: [
      'S.F. EXPRESS (SINGAPORE) PTE LTD',
      '7 Changi South Street 2, #01-01/#03-03,Xilin',
      'Districentre building C, Singapore 486415',
      'Co. Reg No./GST Reg No. 200715259N',
      'Hotline: 1800 3111111 Main fax: +65 6603 0699',
      'www.sf-express.com',
    ],
    tableHeader: [
      'ITEM',
      'DESCRIPTION',
      'PRODUCT',
      'QUANTITY',
      'WEIGHT',
      'AMOUNT',
      'GST/VAT/SST',
      'TOTAL AMT(SGD)',
    ],
  },
  tips: [
    'serviceSupport.invoice.tipContentVN1',
    'serviceSupport.invoice.tipContentVN2',
    'serviceSupport.invoice.tipContentVN3',
    'serviceSupport.invoice.tipContentVN4',
    'serviceSupport.invoice.tipContentVN5',
  ],
}

export default data
