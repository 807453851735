
import HeadBanner from '~/components/common/HeadBanner'
export default {
  components: {
    HeadBanner,
  },
  props: {
    result: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      name: '',
      content: '',
    }
  },
  head() {
    return {
      title: this.$t(`modules.about.relations`) + this.$t('navs.suffixName'),
    }
  },
  computed: {
    service() {
      return this.result
    },
  },
}
