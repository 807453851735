
export default {
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    mode: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    // eslint-disable-next-line vue/require-default-prop
    modelValue: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: String | Array,
      // default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    defaultActiveFirstOption: {
      type: Boolean,
      default: false,
    },
    dropdownMatchSelectWidth: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dropdownStyle: {
        zIndex: '10',
      },
    }
  },
  computed: {
    selectedValue: {
      get() {
        return this.modelValue
      },
      set(v) {
        return this.$emit('update:modelValue', v)
      },
    },
  },
  methods: {
    filterOption(inputValue, option) {
      return option.componentOptions.children[0].text
        .toLowerCase()
        .includes(inputValue.toLowerCase())
    },
  },
}
