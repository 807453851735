
import { mapState } from 'vuex'
import { servicePointList } from './cofig'
import CtMap from '@/components/map/index.vue'
import mbSelect from '@/components/select/mb.vue'
// import mbSelectHttp from '@/components/select/mbHttp.vue'
import CtSelectHttp from '@/components/select/searchHttp.vue'

export default {
  components: {
    CtMap,
    mbSelect,
    CtSelectHttp,
  },
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {
        netType: [
          {
            value: 0,
            label: this.$t('common.form.allData'),
          },
        ],
        keyword: [],
      },
      data: {},
      postCode: undefined,
      postTempCode: undefined,
      postPoint: {},
      radioValue: 1,
      postList: [],
      tabActive: '1',
      searchState: true,
      filterList: [],
      notFoundContent: '',
      countryInfo: {
        sg: {
          zoom: 10,
          center: [103.8692716, 1.3690769],
          extent: [103.499196, 1.109763, 104.159749, 1.572434],
        },
        my: {
          zoom: 5,
          center: [108.6793047, 3.0392257],
          extent: [94.398746, -0.9043689, 118.8705297, 14.637326],
        },
        th: {
          zoom: 5,
          center: [100.56039, 13.79168],
          extent: [92.86835, 4.26731, 108.86144, 23.46386],
        },
        id: {
          zoom: 2,
          center: [110.6793047, -2.1392257],
          extent: [92.398746, -15.1043689, 145.8705297, 10.637326],
        },
        ph: {
          zoom: 4,
          center: [122.6793047, 12.1392257],
          extent: [100.398746, 2.1392257, 156.8705297, 23.1392257],
        },
        vn: {
          zoom: 4,
          center: [104.56039, 15.79168],
          extent: [79.86835, 5.06731, 132.86144, 30.46386],
        },
        au: {
          zoom: 3,
          center: [132.56039, -28.79168],
          extent: [88.86835, -56.41, 180.86144, 20.36],
        },
      },
    }
  },
  computed: {
    ...mapState({
      site: (state) => state.site,
      locale: (state) => state.locale,
    }),
    isSc() {
      return this.locale.includes('zh')
    },
    isNet() {
      return this.radioValue === 1
    },
    disabled() {
      return !this.form.keyword.length
    },
    attrs() {
      return this.countryInfo[this.site]
    },
    hasValue() {
      return Object.keys(this.data).length > 0
    },
    selectList() {
      const dataTypeList = this.dataList.map((data) => data.serviceType)
      const pointList = servicePointList(this) || []
      return pointList.filter(
        (it) => dataTypeList.includes(it.value) || it.value === 0
      )
    },
    isSg() {
      return this.site?.toUpperCase() === 'SG'
    },
    isTH() {
      return this.site?.toUpperCase() === 'TH'
    },
    isTHLang() {
      return this.$route.params.lang.toUpperCase() === 'TH'
    },
    isScOrEn() {
      return ['SC', 'EN'].includes(this.$route.params.lang.toUpperCase())
    },
    getAddress() {
      return (data) => {
        if (this.isScOrEn) {
          return data.addressEn || data.address
        } else {
          return data.address || data.addressEn
        }
      }
    },
    options() {
      const netType = this.form.netType
        .filter((it) => it.value !== 0)
        .map((it) => it.value)
      return this.dataList
        .filter((it) => {
          return netType.length ? netType.includes(it.serviceType) : true
        })
        .map((it) => {
          return {
            value: `${it.postCode}_${it.contactInfo}`,
            label: `${it.selfSendPointName} / ${
              it.postCode
            } / ${this.getAddress(it)}`,
          }
        })
    },
  },
  watch: {
    postTempCode: {
      handler() {
        this.setNotFoundContent()
      },
    },
  },
  mounted() {
    this.filterList = this.dataList
  },
  methods: {
    radioChange() {
      this.reset()
    },
    setNotFoundContent() {
      this.notFoundContent =
        this.postTempCode && this.postTempCode.length < 3 ? (
          <div class="no-point-content">
            {this.$t('serviceSupport.querySupport.service_depts.postLength', {
              len: 3,
            })}
          </div>
        ) : (
          ''
        )
    },
    postSearch(ev) {
      this.postTempCode = ev
      if (ev && ev.length > 2) {
        const params = {
          countryCode: this.site?.toUpperCase(),
          postCode: ev,
        }
        this.$API.support.getPostBound(params).then((res) => {
          this.postList = (res || []).map((it) => {
            it.label = it.countryName + ' ' + it.postCode
            it.value = it.postCode
            return it
          })
        })
      } else {
        this.postList = []
      }
    },
    postChange(item) {
      if (item) {
        const current = this.postList.find((it) => it.postCode === item) || {}
        current.longitude = current.longitude - 0
        current.latitude = current.latitude - 0
        this.postPoint = current
        this.postTempCode = undefined
      } else {
        this.postPoint = {}
      }
    },
    onSelect() {
      this.form.keyword = []
    },
    changeTabActive(active) {
      this.tabActive = active
    },
    changeSearchState() {
      this.searchState = !this.searchState
    },
    selected(data) {
      this.data = data
      if (this.hasValue) this.searchState = false
    },
    reset() {
      this.postCode = undefined
      this.form = {
        netType: [
          {
            value: 0,
            label: this.$t('common.form.allData'),
          },
        ],
        keyword: [],
      }
      this.searchNet()
      this.$refs.map?.resetPoint?.()
    },
    filter(netType, keyword, it) {
      return (
        (netType.length
          ? netType.some((type) => type.value === it.serviceType)
          : true) &&
        (keyword.length
          ? keyword.some(
              (key) => key.value === `${it.postCode}_${it.contactInfo}`
            )
          : true)
      )
    },
    searchAnimate() {
      this.searchState = false
      window.scrollTo({
        top: this.$refs.position.offsetTop,
        behavior: 'smooth',
      })
    },
    searchNet() {
      const { netType, keyword } = this.form
      const typeList = netType.filter((it) => it.value !== 0)
      if (!typeList.length && !keyword.length) {
        this.filterList = this.dataList
      } else {
        this.filterList = this.dataList.filter((it) => {
          return this.filter(typeList, keyword, it)
        })
      }
    },
    searchPost() {
      this.$refs.map.setPoint(this.postPoint)
    },
    search() {
      const timer = setTimeout(() => {
        clearTimeout(timer)
        if (this.isNet) {
          this.searchNet()
          this.searchAnimate()
        } else if (this.postCode && this.postCode.length > 2) {
          if (Object.keys(this.postPoint).length) {
            this.searchPost()
            this.searchNet()
            this.searchAnimate()
          }
        }
      }, 0)
    },
  },
}
