
// https://cn.bing.com/maps/sdkrelease/mapcontrol/isdk/maxbounds
import { init } from '@sf-intl-map/pc'
import { mapState } from 'vuex'

export default {
  props: {
    zoom: {
      type: Number,
      default: 12,
    },
    center: {
      type: Array,
      default: () => [],
    },
    extent: {
      type: Array,
      default: () => [],
    },
    dataList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      map: '',
      // zoom: 12,
      markerItem: '',
      markerRes: '',
      markerSearch: '',
      markLocal: '',
      // center: [103.8392716, 1.3090769],
      // extent: [103.499196, 1.109763, 104.159749, 1.572434],
    }
  },
  computed: {
    ...mapState({
      locale: (state) => state.locale || 'zh-CN',
    }),
  },
  watch: {
    dataList: {
      handler(nv) {
        this.$nextTick(() => {
          this.createMarker(nv)
        })
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initMap()
    })
  },
  beforeDestroy() {
    this.map.destroyMap()
  },
  methods: {
    initMap() {
      console.log('initMap success')
      this.map = init({
        lang: this.locale,
        defaultMap: 'bing',
        container: '#sf-map-container',
        defaultView: {
          extent: this.extent,
          center: this.center,
          zoom: this.zoom,
        },
      })
      this.getLocation()
      // this.setBoundsBorder()
    },
    selectLocation(name = '') {
      const data =
        this.dataList.find((it) => it.selfSendPointName === name) || {}
      this.$emit('selected', data)
    },
    getLocation() {
      this.map
        .toCurrentPosition()
        .then((res) => {
          console.log(res)
          const location = res.location
          if (
            location.lat > this.extent[1] ||
            location.lng > this.extent[0] ||
            location.lat < this.extent[3] ||
            location.lng < this.extent[2]
          ) {
            this.setLocalPoint({
              longitude: location.lng,
              latitude: location.lat,
            })
          } else {
            this.setCenterView()
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    setBoundsBorder() {
      // const cmsMap = this.map.getMap()
      // console.log(111, cmsMap)
    },
    setCenterView(center = [], zoom) {
      this.map.setView({
        zoom: zoom || this.zoom,
        extent: this.extent,
        center: center.length ? center : this.center,
      })
    },
    setFitView(fitList) {
      // console.log(7777, fitList)
      // this.map.setView(fitList)
    },
    resetPoint() {
      this.setCenterView()
      this.markerSearch && this.markerSearch?.removeAll()
    },
    setLocalPoint(point) {
      if (point.longitude && point.latitude) {
        const marker = {
          location: [point.longitude, point.latitude],
          style: {
            src: require('@/assets/img/icons/local_point.png'),
            scale: 0.6,
          },
          showPopup: false,
        }
        this.setCenterView([point.longitude, point.latitude], 14)
        this.markLocal && this.markLocal?.removeAll()
        this.markLocal = this.map.createMarker(marker)
      }
    },
    setPoint(point) {
      if (point.longitude && point.latitude) {
        const marker = {
          location: [point.longitude, point.latitude],
          style: {
            src: require('@/assets/img/icons/search_point.svg'),
            scale: 0.6,
          },
          showPopup: false,
        }
        this.setCenterView([point.longitude, point.latitude], 14)
        this.markerSearch && this.markerSearch?.removeAll()
        this.markerSearch = this.map.createMarker(marker)
      }
    },
    handlerLocationList(dataList) {
      const locationList = dataList.map((it) => {
        const marker = {
          location: [it.longitude, it.latitude],
          properties: {
            name: it.selfSendPointName,
          },
          style: {
            src:
              it.serviceType === 1
                ? require('@/assets/img/icons/drop_point.svg')
                : require('@/assets/img/icons/pick_point.svg'),
            scale: 0.6,
          },
          showPopup: true,
          markerClick: (location, feature) => {
            marker.style.src = require('@/assets/img/icons/active_point.svg')
            this.selectLocation(location.name)
            this.markerItem && this.markerItem.removeAll()
            // this.map.removeMarker(location.name)
            this.markerItem = this.map.createMarker(marker)
          },
        }
        return marker
      })
      return locationList
    },
    handlerFitList(dataList) {
      const fitList = dataList.reduce((total, it) => {
        total.push([it.longitude, it.latitude])
        return total
      }, [])
      return fitList
    },
    createMarker(dataList) {
      if (this.map) {
        this.selectLocation()
        this.markerItem && this.markerItem.removeAll()
        this.markerRes && this.markerRes.removeAll()
        const locationList = this.handlerLocationList(dataList)
        this.markerRes = this.map.createMarker(locationList)
        const fitList = this.handlerFitList(dataList)
        this.setFitView(fitList)
      }
    },
  },
}
