/*
 * @Author: liyang
 * @Date: 2021-04-19 18:53:27
 * @LastEditTime: 2022-05-20 15:29:24
 * @LastEditors: yuanxing
 * @FilePath: \globalweb\utils\queryEle.js
 * @Description: 查找元素方法
 */

export const findTargetParents = (elem, targetEle) => {
  while ((elem = elem.parentNode) && elem.nodeType !== 9) {
    if (elem.nodeType === 1) {
      if (elem === targetEle) {
        return true
      }
    }
  }
  return false
}

export const addEventListener = (element, type, fn) => {
  if (process.client) {
    if (element.addEventListener) {
      element.addEventListener(type, fn, false)
    } else if (element.attachEvent) {
      element.attachEvent('on' + type, fn)
    } else {
      element['on' + type] = fn
    }
  }
}

export const removeEventListener = (element, type, fnName) => {
  if (process.client) {
    if (element.removeEventListener) {
      element.removeEventListener(type, fnName, false)
    } else if (element.detachEvent) {
      element.detachEvent('on' + type, fnName)
    } else {
      element['on' + type] = null
    }
  }
}
