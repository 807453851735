/*
 * @Author: yuanxing(01407381)
 * @Date: 2023-03-07 11:09:52
 * @LastEditors: yuanxing(01407381)
 * @LastEditTime: 2023-05-19 14:59:49
 * @FilePath: \ibu-owt-core-frontend\site\country\sg\index.js
 * @Description:
 */
import Index from './index/index.vue'
import Consign from './consign/index.vue'
import Product from './product/index.vue'
import Contact from './contact/index.vue'
import Dept from './dept/index.vue'
import Invoice from './invoice/index.vue'

export const pages = {
  index: Index,
  consign: Consign,
  product: Product,
  contact: Contact,
  dept: Dept,
  invoice: Invoice, // 发票申请
}
